import { useEffect, useState } from 'react'
import { downloadFile } from '../../utilities/restService'
import { useParams } from 'react-router-dom'
import CircularLoading from '../../shared/CircularLoading'

export default function Images(props) {
  let { imageName, deploymentMode } = useParams()

  const [isLoading, setIsLoading] = useState(false)

  const downloadFileFromToss = async (imageName, deploymentMode) => {
    setIsLoading(true)
    const fileRes = await downloadFile(imageName, deploymentMode)
    if (fileRes?.status === 200) {
      var fileName = imageName.split(/_(.*)/s)[1]
      fileName = fileName.replaceAll('_', ' ')
      var contentType = 'application/octet-stream'
      var a = document.createElement('a')
      var blob = new File([fileRes?.data], { type: contentType })
      a.href = window.URL.createObjectURL(blob)
      a.download = fileName
      a.click()
      setIsLoading(false)
    }
  }
  useEffect(() => {
    downloadFileFromToss(imageName, deploymentMode)
  }, [imageName, deploymentMode])
  return (
    <>
      {isLoading ? (
        <h1>
          Downloading Started <CircularLoading />
        </h1>
      ) : (
        <h1>Downloading Completed</h1>
      )}
    </>
  )
}
