import React, { Component } from 'react'
import * as SurveyKo from 'survey-knockout'
import * as SurveyJSCreator from 'survey-creator'
import 'survey-creator/survey-creator.css'

import 'jquery-ui/themes/base/all.css'
import 'nouislider/distribute/nouislider.css'
import 'select2/dist/css/select2.css'
import 'bootstrap-slider/dist/css/bootstrap-slider.css'

import 'jquery-bar-rating/dist/themes/css-stars.css'
import 'jquery-bar-rating/dist/themes/fontawesome-stars.css'

import $ from 'jquery'
import 'jquery-ui/ui/widgets/datepicker.js'
import 'select2/dist/js/select2.js'
import 'jquery-bar-rating'

import 'pretty-checkbox/dist/pretty-checkbox.css'
import * as widgets from 'surveyjs-widgets'
import '../../utilities/QuillEditortcustomwidget'
import '../../utilities/customMethods'

import {
  getSurveyFormsBySurveyId,
  createSurveyForm,
  updateSurveyForm,
  getAllLocations,
  getRecipientsByEmailId,
} from '../../utilities/restService'
import CustomizedSnackbars from '../../shared/CustomizedSnackbars'
import { withEnv } from '@praxis/component-runtime-env'

var mainColor = '#8c1f27'
var mainHoverColor = '#8c1f27'
var textColor = '#4a4a4a'
var headerColor = '#8c1f27'
var headerBackgroundColor = '#ffff'
var bodyContainerBackgroundColor = '#f8f8f8'

var defaultThemeColorsSurvey = SurveyKo.StylesManager.ThemeColors['default']
defaultThemeColorsSurvey['$main-color'] = mainColor
defaultThemeColorsSurvey['$main-hover-color'] = mainHoverColor
defaultThemeColorsSurvey['$text-color'] = textColor
defaultThemeColorsSurvey['$header-color'] = headerColor
defaultThemeColorsSurvey['$header-background-color'] = headerBackgroundColor
defaultThemeColorsSurvey['$body-container-background-color'] =
  bodyContainerBackgroundColor

var defaultThemeColorsEditor =
  SurveyJSCreator.StylesManager.ThemeColors['default']
defaultThemeColorsEditor['$primary-color'] = mainColor
defaultThemeColorsEditor['$secondary-color'] = mainColor
defaultThemeColorsEditor['$primary-hover-color'] = mainHoverColor
defaultThemeColorsEditor['$primary-text-color'] = textColor
defaultThemeColorsEditor['$selection-border-color'] = mainColor

SurveyKo.StylesManager.ThemeCss[
  '.sv_main.sv_default_css .sv_progress-buttons__list li.sv_progress-buttons__list-element--current::before'
] = 'background-color: $main-color; border: 5px solid $main-hover-color;'
SurveyKo.StylesManager.ThemeCss[
  '.sv_main.sv_default_css .sv_progress-buttons__list li.sv_progress-buttons__list-element--passed.sv_progress-buttons__list-element--current::before '
] = 'background-color: $main-color; border: 5px solid $main-hover-color;'
SurveyKo.StylesManager.ThemeCss[
  '.sv_main.sv_default_css .sv_progress-buttons__list li.sv_progress-buttons__list-element--passed::before'
] = 'background-color: #ffffff; border: 5px solid $main-hover-color;'
SurveyKo.StylesManager.ThemeCss[
  '.sv_main.sv_default_css .sv_progress-buttons__list li::before'
] = 'background-color: #ffffff; border: 5px solid $main-hover-color;'

SurveyKo.StylesManager.applyTheme()
SurveyJSCreator.StylesManager.applyTheme()

// SurveyJSCreator.StylesManager.applyTheme('default')

widgets.jqueryuidatepicker(SurveyKo, $)

SurveyKo.Serializer.addProperty('survey', {
  name: 'include_save_button',
  displayName: 'Include save button?',
  type: 'boolean',
  default: true,
  category: 'general',
  visibleIndex: 3,
})

SurveyKo.Serializer.addProperty('survey', {
  name: 'finding_description',
  displayName: 'Finding Description',
  type: 'text',
  category: 'general',
  visibleIndex: 5,
})
SurveyKo.Serializer.addProperty('question', {
  name: 'finding_action',
  displayName: 'Finding Action',
  type: 'text',
  category: 'general',
  visibleIndex: 5,
})

//Add property
SurveyKo.Serializer.addProperty('question', {
  name: 'role',
  default: 'user',
  choices: ['user', 'user-readOnly', 'admin', 'admin-user'],
  category: 'general',
})

//Add property
SurveyKo.Serializer.addProperty('question', {
  name: 'include_pdf',
  displayName: 'Include in PDF?',
  type: 'boolean',
  default: true,
  category: 'general',
  visibleIndex: 3,
})

//Add property
SurveyKo.Serializer.addProperty('question', {
  name: 'includePdfIf:condition',
  category: 'logic',
})
//Add logic type
SurveyJSCreator.SurveyLogic.types.push({
  name: 'question_includepdf',
  baseClass: 'question',
  propertyName: 'includePdfIf',
  showIf: function (survey) {
    return survey.getAllQuestions().length > 0
  },
})

//Add Create finding property
SurveyKo.Serializer.addProperty('question', {
  name: 'create_finding',
  displayName: 'Create Finding',
  type: 'boolean',
  default: false,
  category: 'general',
  visibleIndex: 4,
})

//Add create finding in logic
SurveyKo.Serializer.addProperty('question', {
  name: 'createFindingIf:condition',
  category: 'logic',
})
//Add logic type
SurveyJSCreator.SurveyLogic.types.push({
  name: 'question_createfinding',
  baseClass: 'question',
  propertyName: 'createFindingIf',
  showIf: function (survey) {
    return survey.getAllQuestions().length > 0
  },
})

//Localized strings
var locLogic = SurveyJSCreator.defaultStrings.ed.lg
locLogic['question_includepdfName'] = 'Question PDF visibility'
locLogic['question_includepdfDescription'] =
  'Make the question pdf visible when the logic expression returns true. Otherwise keep it invisible.'
locLogic['question_includepdfText'] =
  'Make question include  in PDF {0} visible'

locLogic['question_createfindingName'] = 'Question Create Finding visibility'
locLogic['question_createfindingDescription'] =
  'Make the question Create Finding visible when the logic expression returns true. Otherwise keep it invisible.'
locLogic['question_createfindingText'] =
  'Make question Create Finding {0} visible'

class SurveyCreator extends Component {
  surveyCreator
  constructor(props) {
    super(props)
    this.state = {
      allLocations: [],
      isSuccess: false,
      isError: false,
      message: '',
    }
  }

  componentDidMount() {
    const { session, env } = this.props
    let options = {
      haveCommercialLicense: true,
      showEmbededSurveyTab: true,
      showJSONEditorTab: true,
      showLogicTab: true,
      showToolbox: 'right',
      showPropertyGrid: 'right',
      designerHeight: '1200px',
      showSurveyTitle: 'always',
    }

    function setPdfFlagTrue(params) {
      if (params.length < 1) return
      params[0].isPdfSend = true
      return params[0]
    }
    SurveyKo.FunctionFactory.Instance.register('setPdfFlagTrue', setPdfFlagTrue)

    function setEmailFlagTrue(params) {
      if (params.length < 1) return
      params[0].isEmailSend = true
      params[0].isPdfSend = true
      return params[0]
    }
    SurveyKo.FunctionFactory.Instance.register(
      'setEmailFlagTrue',
      setEmailFlagTrue,
    )

    function setPdfFlagFalse(params) {
      if (params.length < 1) return
      params[0].isPdfSend = false
      return params[0]
    }
    SurveyKo.FunctionFactory.Instance.register(
      'setPdfFlagFalse',
      setPdfFlagFalse,
    )

    function setEmailFlagFalse(params) {
      if (params.length < 1) return
      params[0].isEmailSend = false
      params[0].isPdfSend = false
      return params[0]
    }
    SurveyKo.FunctionFactory.Instance.register(
      'setEmailFlagFalse',
      setEmailFlagFalse,
    )

    SurveyKo.ChoicesRestfull.onBeforeSendRequest = function (sender, options) {
      options.request.setRequestHeader(
        'Authorization',
        session?.userInfo?.accessToken,
      )
      options.request.setRequestHeader('x-api-key', env?.key)
    }

    if (
      SurveyKo.ComponentCollection.Instance.getCustomQuestionByName('date') ===
      null
    ) {
      SurveyKo.ComponentCollection.Instance.add({
        name: 'Date',
        category: 'General',
        questionJSON: {
          type: 'text',
          name: 'question1',
          inputType: 'date',
        },
      })
    }

    if (
      SurveyKo.ComponentCollection.Instance.getCustomQuestionByName(
        'emailnotification',
      ) === null
    ) {
      SurveyKo.ComponentCollection.Instance.add({
        name: 'EmailNotification',
        category: 'Custom',
        elementsJSON: [
          {
            type: 'text',
            name: 'to_email',
            title: 'To',
            hideNumber: true,
          },
          {
            type: 'text',
            name: 'bcc_email',
            title: 'BCC',
            hideNumber: true,
          },
          {
            type: 'text',
            name: 'subject_email',
            title: 'Subject',
            hideNumber: true,
          },
          {
            type: 'quilleditor',
            name: 'body_email',
            title: 'Email body',
            hideNumber: true,
          },
          {
            type: 'boolean',
            name: 'isEmailSend',
            title: 'Send Email?',
            hideNumber: true,
            isRequired: true,
          },
          {
            type: 'boolean',
            name: 'isPdfSend',
            title: 'Send PDF?',
            hideNumber: true,
            isRequired: true,
          },
        ],
      })
    }
    if (
      SurveyKo.ComponentCollection.Instance.getCustomQuestionByName(
        'workdaywithoutdefault',
      ) === null
    ) {
      SurveyKo.ComponentCollection.Instance.add({
        name: 'WorkdayWithOutDefault',
        category: 'Custom',
        elementsJSON: [
          {
            type: 'text',
            name: 'workday',
            title: 'Email',
          },
          {
            type: 'html',
            name: 'requesting1',
            html: 'The data is requesting',
            visibleIf: '{email_request_processing1} = true',
          },
          {
            type: 'html',
            name: 'error1',
            html: '<div style=color:red><b>Please type the email correctly</b></div>',
            visibleIf: '{email_request_error1} = true',
          },
        ],
        onValueChanged: (question, name, value) => {
          if (name === 'workday') {
            this.getUserData1(question, name, value)
          }
        },
      })
    }

    if (
      SurveyKo.ComponentCollection.Instance.getCustomQuestionByName(
        'workday',
      ) === null
    ) {
      SurveyKo.ComponentCollection.Instance.add({
        name: 'Workday',
        category: 'Custom',
        elementsJSON: [
          {
            type: 'text',
            name: 'workday',
            title: 'Email',
          },
          {
            type: 'html',
            name: 'requesting',
            html: 'The data is requesting',
            visibleIf: '{email_request_processing} = true',
          },
          {
            type: 'html',
            name: 'error',
            html: '<div style=color:red><b>Please type the email correctly</b></div>',
            visibleIf: '{email_request_error} = true',
          },
        ],
        onValueChanged: (question, name, value) => {
          if (name === 'workday') {
            this.getUserData(question, name, value)
          }
        },
      })
    }

    if (
      SurveyKo.ComponentCollection.Instance.getCustomQuestionByName(
        'supervisoremail',
      ) === null
    ) {
      SurveyKo.ComponentCollection.Instance.add({
        //Unique component name. It becomes a new question type. Please note, it should be written in lowercase.
        name: 'Supervisoremail',
        //The text that shows on toolbox
        title: 'Supervisor Email',
        category: 'Custom',
        //The actual question that will do the job
        questionJSON: {
          type: 'text',
          inputType: 'email',
          name: 'supervisoremail',
          title: 'Supervisor email',
        },
      })
    }

    if (
      SurveyKo.ComponentCollection.Instance.getCustomQuestionByName(
        'location',
      ) === null
    ) {
      SurveyKo.ComponentCollection.Instance.add({
        name: 'Location',
        category: 'Custom',
        elementsJSON: [
          {
            type: 'dropdown',
            name: 'location',
            isRequired: true,
            renderAs: 'select2',
            choicesByUrl: {
              url:
                this.props?.env?.fieldAssessment?.host +
                '/locations?key=' +
                this.props?.env?.key,
              valueName: 'location_id',
              titleName: 'location_name',
            },
          },
          { type: 'text', name: 'region', readOnly: true },
          { type: 'text', name: 'group', readOnly: true },
          { type: 'text', name: 'district', readOnly: true },
          { type: 'text', name: 'state', readOnly: true },
        ],
        onValueChanged: (question, name, value) => {
          //If location is changed, then set a region, group and distric values
          if (name === 'location') {
            var filteredLocation = this.state.allLocations.filter(
              (l) => l.location_id === value,
            )

            question.setValue('region', filteredLocation[0].region)
            question.setValue('group', filteredLocation[0].group)
            question.setValue('district', filteredLocation[0].district)
            question.setValue('state', filteredLocation[0].state)
          }
        },
      })
    }

    this.surveyCreator = new SurveyJSCreator.SurveyCreator(null, options)
    this.surveyCreator.rightContainerActiveItem('toolbox')
    this.surveyCreator.toolbox.allowExpandMultipleCategories = true

    // setting default values for file items
    var file = this.surveyCreator.toolbox.getItemByName('file')
    file.json = {
      type: 'file',
      storeDataAsText: false,
      showPreview: true,
      maxSize: 16096000,
      imageHeight: 100,
      imageWidth: 150,
    }

    this.surveyCreator.toolbox.changeCategories([
      {
        name: 'panel',
        category: 'Panels',
      },
      {
        name: 'paneldynamic',
        category: 'Panels',
      },
      {
        name: 'matrix',
        category: 'Matrix',
      },
      {
        name: 'matrixdropdown',
        category: 'Matrix',
      },
      {
        name: 'matrixdynamic',
        category: 'Matrix',
      },
    ])

    this.surveyCreator.toolbox.removeItem('datepicker')

    this.surveyCreator.saveSurveyFunc = this.saveMySurvey

    this.surveyCreator.render('surveyCreatorContainer')
    this.surveyCreator.JSON = {
      pages: [
        {
          name: 'page1',
        },
      ],
      clearInvisibleValues: 'none',
      pageNextText: 'Save & Continue',
    }
  }
  componentWillMount() {
    getAllLocations().then((res) => {
      if (res.data !== null) {
        this.setState({ allLocations: res?.data })
      }
    })
    if (this.props.id !== undefined) {
      getSurveyFormsBySurveyId(this.props.id)
        .then((res) => {
          if (res.data !== null) {
            this.surveyCreator.JSON = res?.data?.survey_form_json
          }
        })
        .catch((error) => {})
    }
  }

  render() {
    const { isSuccess, isError, message } = this.state
    return (
      <React.Fragment>
        {isSuccess ? (
          <CustomizedSnackbars variant="success" message={message} />
        ) : isError ? (
          <CustomizedSnackbars variant="error" message={message} />
        ) : (
          ''
        )}
        <div id="surveyCreatorContainer" />
      </React.Fragment>
    )
  }
  getUserData = async (question, name, value) => {
    question.setVariable('email_request_processing', false)
    question.setVariable('email_request_error', false)
    if (value.trim() !== '') {
      question.setVariable('email_request_processing', true)
      question.setVariable('email_request_error', false)
      getRecipientsByEmailId(value)
        .then((res) => {
          question.setVariable('email_request_processing', false)
        })
        .catch((error) => {
          question.setVariable('email_request_processing', false)
          question.setVariable('email_request_error', true)
        })
    }
  }

  getUserData1 = async (question, name, value) => {
    question.setVariable('email_request_processing1', false)
    question.setVariable('email_request_error1', false)
    if (value.trim() !== '') {
      question.setVariable('email_request_processing1', true)
      question.setVariable('email_request_error1', false)
      getRecipientsByEmailId(value)
        .then((res) => {
          question.setVariable('email_request_processing1', false)
        })
        .catch((error) => {
          question.setVariable('email_request_processing1', false)
          question.setVariable('email_request_error1', true)
        })
    }
  }
  saveMySurvey = () => {
    this.setState({ isSuccess: false, isError: false })
    if (this.surveyCreator.JSON.title === undefined) {
      this.setState({ isError: true, message: 'Survey title is required.' })
    } else {
      var request = {
        name: this.surveyCreator.JSON.title,
        description: this.surveyCreator?.JSON?.description,
        survey_form_json: this.surveyCreator?.JSON,
      }

      if (this.props?.id !== undefined) {
        updateSurveyForm(this.props?.id, request)
          .then((res) => {
            this.setState({
              isSuccess: true,
              message: 'Survey updated successfully',
            })
          })
          .catch((error) => {})
      } else {
        createSurveyForm(request)
          .then((res) => {
            this.setState({
              isSuccess: true,
              message: 'Survey created successfully',
            })
            this.props.navigate(
              '/application/' + this.props?.appId + '/update/' + res.data.id,
            )
          })
          .catch((error) => {})
      }
    }
  }
}

export default withEnv()(SurveyCreator)
