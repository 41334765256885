import React from 'react'
import { AuthProvider } from '@praxis/component-auth'
import { useEnv } from '@praxis/component-runtime-env'
import './App.css'
import Layout from './components/layout/Layout'
import ApplicationContextProvider from './components/contexts/ApplicationContext'
import { BrowserRouter } from 'react-router-dom'
export const Main = () => {
  return (
    <React.Fragment>
      <BrowserRouter>
        <Layout />
      </BrowserRouter>
    </React.Fragment>
  )
}

export const App = () => {
  const env = useEnv()
  return (
    <AuthProvider {...env.auth}>
      <ApplicationContextProvider>
        <Main />
      </ApplicationContextProvider>
    </AuthProvider>
  )
}
export default App
