import React, { useState, useEffect, useContext } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { getAllSurveys } from '../utilities/restService'
import withStyles from '@material-ui/core/styles/withStyles'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Collapse from '@material-ui/core/Collapse'
import EditIcon from '@material-ui/icons/Edit'
import CustomTooltip from '../shared/CustomTooltip'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import PublishIcon from '@material-ui/icons/Publish'
import DeleteIcon from '@material-ui/icons/Delete'
import CircularLoading from '../shared/CircularLoading'
import { formatAMAndPM, timeZone, formatedDateTime } from '../utilities/date'
import { confirmAlert } from 'react-confirm-alert'
import { useAuth } from '@praxis/component-auth'
import {
  ASSESSMENT_TYPE_FILTERS,
  ASSESSMENT_TYPE_FILTER_OPTIONS,
  ASSESSMENT_OWNER,
  ROLE,
} from '../utilities/constants'
import { softDeleteSurvey } from '../utilities/restService'
import { ApplicationContext } from '../components/contexts/ApplicationContext'

const styles = (theme) => {
  return {
    root: {
      maxWidth: 280,
      minWidth: 280,
      marginLeft: 13,
      marginTop: 10,
      height: 250,
    },
    formControl: {
      margin: theme.spacing(1),
      maxWidth: 330,
      minWidth: 330,
      marginLeft: 13,
      marginBottom: 20,
    },
    selectComponent: {
      '&:before': {
        borderColor: '#8C1F27',
      },
      '&:after': {
        borderColor: '#8C1F27',
      },
      '&:hover': {
        borderColor: '#8C1F27',
        borderWidth: 2,
      },
      fontSize: '14px',
    },
    title: {
      fontSize: 13,
    },
    iconSize: {
      width: 25,
      height: 25,
    },
    margin13: {
      marginLeft: 15,
      marginTop: 4,
      marginBottom: 13,
    },
  }
}

const HomePage = (props) => {
  const auth = useAuth()
  const { session } = auth
  const { classes } = props
  const [assessmentsStatus, setAssessmentsStatus] = useState(
    ASSESSMENT_OWNER.OWN,
  )
  const [isLoading, setIsLoading] = useState(false)
  const [fieldAssessmentData, setFieldAssessmentData] = useState([])
  const [assessmentFilterType, setAssessmentFilterType] = useState(
    ASSESSMENT_TYPE_FILTER_OPTIONS.MY_ADMIN_ASSESSMENTS,
  )
  const { setAppId, roleIdentities } = useContext(ApplicationContext)
  let { appId } = useParams()

  var filterRoles = roleIdentities
    ?.filter((r) => r.application_id.toString() === appId.toString())
    .map((r) => r.role)

  function getAssessmentsData(filterOption) {
    setIsLoading(true)
    getAllSurveys(filterOption)
      .then((res) => {
        setFieldAssessmentData(res.data)
        setIsLoading(false)
      })
      .catch((error) => {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    if (appId !== undefined) {
      setAppId(appId)
    }
    getAssessmentsData(ASSESSMENT_OWNER.OWN)
  }, [appId, setAppId])

  function handleAssessmentFilter(value) {
    setAssessmentFilterType(value)
    if (value === ASSESSMENT_TYPE_FILTER_OPTIONS.MY_ADMIN_ASSESSMENTS) {
      getAssessmentsData(ASSESSMENT_OWNER.OWN)
      setAssessmentsStatus(ASSESSMENT_OWNER.OWN)
    } else if (value === ASSESSMENT_TYPE_FILTER_OPTIONS.ALL_ASSESSMENTS) {
      getAssessmentsData(ASSESSMENT_OWNER.ALL)
      setAssessmentsStatus(ASSESSMENT_OWNER.ALL)
    }
  }

  function deleteSurveyId(surveyId) {
    confirmAlert({
      title: 'Delete Survey',
      message:
        'Are you sure you want to delete this Survey:' +
        surveyId +
        '? This will delete all its completed Deployments and assessments as well',
      closeOnEscape: false,
      closeOnClickOutside: false,
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            softDeleteSurvey(surveyId)
              .then((res) => {
                if (res.data.is_deleted === true) {
                  getAssessmentsData(assessmentsStatus)
                } else {
                  alert('Delete unsuccessful')
                }
              })
              .catch((error) => {
                alert('Delete unsuccessful')
              })
          },
        },
        {
          label: 'No',
          onClick: () => {
            return false
          },
        },
      ],
    })
  }

  return (
    <React.Fragment>
      <Helmet title="Home Page" />
      <Grid container>
        <Grid item xs={11}>
          <Typography variant="h4" className={classes.margin13}>
            Field Assessments
          </Typography>
        </Grid>
        <Grid item>
          {filterRoles?.includes(ROLE.ADMIN) && (
            <CustomTooltip title={'Create Assessment'}>
              <Link
                color={'primary'}
                to={{ pathname: '/application/' + appId + '/creator' }}
                style={{ textDecoration: 'none' }}
              >
                <AddCircleIcon className={classes.iconSize} />
              </Link>
            </CustomTooltip>
          )}
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={2} className={classes.margin13}>
          <FormControl fullWidth>
            <Select
              className={classes.selectComponent}
              labelId="assessmentFilterType-select-label"
              id="assessmentFilterType-select"
              name="assessmentFilterType"
              value={assessmentFilterType}
              onChange={(e) => handleAssessmentFilter(e.target.value)}
            >
              {ASSESSMENT_TYPE_FILTERS.map((d, index) => {
                return (
                  <MenuItem style={{ fontSize: '13px' }} key={index} value={d}>
                    {d}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      {isLoading ? (
        <CircularLoading />
      ) : (
        <Grid container>
          {fieldAssessmentData.map((field, index) => {
            return (
              <Grid item key={index}>
                {field.is_deleted !== true ? (
                  <Card className={classes.root}>
                    <Collapse
                      style={{ backgroundColor: '#aaa' }}
                      collapsedHeight={'5.6em'}
                      timeout="auto"
                      title={field.name}
                    >
                      <CardContent>
                        <Typography
                          variant="h1"
                          style={{
                            color: '#333',
                            fontSize: '17px',
                            fontWeight: 'bold',
                          }}
                        >
                          {field.name !== null &&
                          field.name !== undefined &&
                          field.name !== ''
                            ? field.name.length > 70
                              ? field.name.slice(0, 70) + '...'
                              : field.name
                            : ''}
                        </Typography>
                      </CardContent>
                    </Collapse>
                    <CardContent>
                      <Typography className={classes.title}>
                        Id: {field.id}
                      </Typography>
                      <Typography className={classes.title}>
                        Created on:{' '}
                        {field.created_date === null
                          ? ''
                          : formatAMAndPM(
                              new Date(
                                formatedDateTime(new Date(field.created_date)) +
                                  timeZone,
                              ),
                            )}
                      </Typography>
                      <Typography className={classes.title}>
                        Created by: {field.created_by}
                      </Typography>
                      <Typography className={classes.title}>
                        Modified on:{' '}
                        {field.modified_date === null
                          ? ''
                          : formatAMAndPM(
                              new Date(
                                formatedDateTime(
                                  new Date(field.modified_date),
                                ) + timeZone,
                              ),
                            )}
                      </Typography>
                      <Typography className={classes.title}>
                        Modified by: {field.modified_by}
                      </Typography>
                    </CardContent>
                    {filterRoles?.includes(ROLE.ADMIN) && (
                      <CardActions>
                        <CustomTooltip title={'Edit'}>
                          <Link
                            color={'primary'}
                            to={{
                              pathname:
                                '/application/' + appId + '/update/' + field.id,
                            }}
                            style={{ textDecoration: 'none' }}
                          >
                            <EditIcon className={classes.iconSize} />
                          </Link>
                        </CustomTooltip>
                        <CustomTooltip title={'Deploy Survey'}>
                          <Link
                            color={'primary'}
                            to={{
                              pathname:
                                '/application/' +
                                appId +
                                '/createDeployment/' +
                                field.id,
                            }}
                            style={{ textDecoration: 'none' }}
                          >
                            <PublishIcon className={classes.iconSize} />
                          </Link>
                        </CustomTooltip>

                        {field.created_by === session?.userInfo?.lanId ||
                        (field.modified_by != null &&
                          field.modified_by === session?.userInfo?.lanId) ? (
                          <CustomTooltip title={'Delete Survey'}>
                            <Link
                              color={'primary'}
                              style={{ textDecoration: 'none' }}
                            >
                              <DeleteIcon
                                className={classes.iconSize}
                                onClick={(e) => {
                                  e.preventDefault()
                                  deleteSurveyId(field.id)
                                }}
                              />
                            </Link>
                          </CustomTooltip>
                        ) : (
                          ''
                        )}
                      </CardActions>
                    )}
                  </Card>
                ) : (
                  ''
                )}
              </Grid>
            )
          })}
        </Grid>
      )}
    </React.Fragment>
  )
}

export default withStyles(styles)(HomePage)
