import 'date-fns'
import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import withStyles from '@material-ui/core/styles/withStyles'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import CustomTooltip from '../../shared/CustomTooltip'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import muiTheme from '../../config/themeConfig'
import MUIDataGrid from '../../shared/MUIDataGrid'
import { getAllCustomGroups } from '../../utilities/restService'
import CircularLoading from '../../shared/CircularLoading'
import { formatAMAndPM, timeZone, formatedDateTime } from '../../utilities/date'

const styles = (theme) => ({
  iconSize: {
    width: 25,
    height: 25,
  },
  margin13: {
    marginLeft: 13,
    marginTop: 4,
  },
})

const GroupComponent = (props) => {
  const { classes } = props
  const [isLoading, setIsLoading] = useState(false)
  const [groupData, setGroupData] = useState([])
  let navigate = useNavigate()

  useEffect(() => {
    setIsLoading(true)
    getAllCustomGroups()
      .then((res) => {
        setGroupData(res.data)
        setIsLoading(false)
      })
      .catch((error) => {
        setIsLoading(false)
      })
  }, [])

  // Group Columns
  const GROUP_COLUMNS = [
    {
      name: 'id',
      label: 'Id',
      options: {
        filter: false,
      },
    },
    {
      name: 'name',
      label: 'Name',
      options: {
        filter: false,
      },
    },
    {
      name: 'created_by',
      label: 'Created By',
      options: {
        filter: true,
      },
    },
    {
      name: 'created_date',
      label: 'Created Date',
      options: {
        filter: true,
        customBodyRender: (value) =>
          value === null
            ? ''
            : formatAMAndPM(
                new Date(formatedDateTime(new Date(value)) + timeZone),
              ),
      },
    },
    {
      name: 'modified_by',
      label: 'Modified By',
      options: {
        filter: true,
      },
    },
    {
      name: 'modified_date',
      label: 'Modified Date',
      options: {
        filter: true,
        customBodyRender: (value) =>
          value === null
            ? ''
            : formatAMAndPM(
                new Date(formatedDateTime(new Date(value)) + timeZone),
              ),
      },
    },
  ]

  const options = {
    rowsPerPage: 25,
    filterType: 'dropdown',
    responsive: 'standard',
    fixedHeader: true,
    tableBodyHeight: '75vh',
    rowsPerPageOptions: [25, 50, 100, 200],
    rowHover: true,
    sort: true,
    filter: true,
    print: false,
    viewColumns: true,
    download: false,
    search: true,
    selectableRows: 'none',
    onRowClick: (rowData) => {
      navigate('/groupUpdate/' + rowData[0])
    },
  }

  return (
    <React.Fragment>
      <Helmet title="Groups" />
      <div>
        <Grid container>
          <Grid item xs={11}></Grid>
          <Grid item>
            <CustomTooltip title={'Create'}>
              <Link
                color={'primary'}
                to={{ pathname: '/groupCreator' }}
                style={{ textDecoration: 'none' }}
              >
                <AddCircleIcon className={classes.iconSize} />
              </Link>
            </CustomTooltip>
          </Grid>
        </Grid>
        <MuiThemeProvider theme={muiTheme}>
          {isLoading ? (
            <CircularLoading />
          ) : (
            <MUIDataGrid
              title="Custom Groups data"
              rows={groupData}
              columns={GROUP_COLUMNS}
              options={options}
            />
          )}
        </MuiThemeProvider>
      </div>
    </React.Fragment>
  )
}

export default withStyles(styles)(GroupComponent)
