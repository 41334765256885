import 'date-fns'
import React, { useState, useEffect } from 'react'
import { useAuth } from '@praxis/component-auth'
import {
  formatAMAndPM,
  formatDate,
  timeZone,
  formatedDateTime,
} from '../../utilities/date'
import { Helmet } from 'react-helmet'
import { useNavigate } from 'react-router-dom'
import withStyles from '@material-ui/core/styles/withStyles'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import muiTheme from '../../config/themeConfig'
import MUIDataGrid from '../../shared/MUIDataGrid'
import { getAllDeploymentsByEmail } from '../../utilities/restService'
import CircularLoading from '../../shared/CircularLoading'
import Grid from '@material-ui/core/Grid'
import { FormControl, MenuItem, Select } from '@material-ui/core'
import Collapse from '@material-ui/core/Collapse'
import PollIcon from '@material-ui/icons/Poll'
import CustomTooltip from '../../shared/CustomTooltip'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import { Link } from 'react-router-dom'
import {
  ASSIGNED_DEPLOYMENT_TYPE_FILTERS_OPTIONS,
  ASSIGNED_DEPLOYMENT_TYPE_FILTERS,
  STATUS,
} from '../../utilities/constants'

const styles = (theme) => ({
  root: {
    maxWidth: 300,
    minWidth: 300,
    marginLeft: 13,
    marginTop: 10,
    height: 285,
  },
  title: {
    fontSize: 13,
  },
  dropdownSize: {
    fontSize: 18,
  },
  iconSize: {
    width: 25,
    height: 25,
  },
  margin13: {
    marginLeft: 13,
    marginTop: 4,
  },
  margin25: {
    marginTop: 25,
  },
  cardContent1: {
    backgroundColor: '#EAEAEA',
  },
  cardContent2: {
    backgroundColor: '#0000',
  },
})

const Survey = (props) => {
  const { classes } = props
  const auth = useAuth() // connect to the \`AuthContext\` provided by \`App\` below, using a React hook.
  const { session } = auth // assign variables of same name as properties off of \`auth\`
  const [isLoading, setIsLoading] = useState(false)

  const [oneTimeSurveyData, setOneTimeSurveyData] = useState([])
  const [PersistentSurveyData, setPersistentSurveyData] = useState([])
  const [deploymentFilterType, setDeploymentFilterType] = useState(
    ASSIGNED_DEPLOYMENT_TYPE_FILTERS_OPTIONS.MY_OPEN_DEPLOYMENTS,
  )
  let navigate = useNavigate()

  useEffect(() => {
    setIsLoading(true)
    getAllDeploymentsByEmail(STATUS.OPEN)
      .then((res) => {
        setOneTimeSurveyData(
          res.data.filter((one) => one.deployment_type === 'One Time'),
        )
        setPersistentSurveyData(
          res.data.filter((p) => p.deployment_type === 'Persistent'),
        )
        setIsLoading(false)
      })
      .catch((error) => {
        setIsLoading(false)
      })
  }, [session])

  // Group Columns
  const SURVEY_COLUMNS = [
    {
      name: 'id',
      label: 'Id',
      options: {
        filter: false,
      },
    },
    {
      name: 'name',
      label: 'Name',
      options: {
        filter: false,
      },
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        filter: false,
      },
    },
    {
      name: 'deployment_type',
      label: 'Deployment Type',
      options: {
        filter: false,
      },
    },
    {
      name: 'created_by',
      label: 'Created By',
      options: {
        filter: true,
      },
    },
    {
      name: 'created_date',
      label: 'Created Date',
      options: {
        filter: true,
        customBodyRender: (value) =>
          value === null
            ? ''
            : formatAMAndPM(
                new Date(formatedDateTime(new Date(value)) + timeZone),
              ),
      },
    },
    {
      name: 'modified_by',
      label: 'Modified By',
      options: {
        filter: true,
      },
    },
    {
      name: 'modified_date',
      label: 'Modified Date',
      options: {
        filter: true,
        customBodyRender: (value) =>
          value === null
            ? ''
            : formatAMAndPM(
                new Date(formatedDateTime(new Date(value)) + timeZone),
              ),
      },
    },
    {
      name: 'due_date',
      label: 'Due Date',
      options: {
        filter: true,
        customBodyRender: (value) =>
          value !== null ? formatDate(new Date(value)) : '',
      },
    },
  ]

  const options = {
    rowsPerPage: 25,
    filterType: 'dropdown',
    responsive: 'standard',
    fixedHeader: true,
    tableBodyHeight: '75vh',
    rowsPerPageOptions: [25, 50, 100, 200],
    rowHover: true,
    sort: true,
    filter: true,
    print: false,
    viewColumns: true,
    download: false,
    search: true,
    selectableRows: 'none',
    onRowClick: (rowData) => {
      navigate('/surveyAnswerUrl/' + rowData[0])
    },
  }

  function getDeployments(status) {
    getAllDeploymentsByEmail(status)
      .then((res) => {
        setOneTimeSurveyData(
          res.data.filter((one) => one.deployment_type === 'One Time'),
        )
        setPersistentSurveyData(
          res.data.filter((p) => p.deployment_type === 'Persistent'),
        )
        setIsLoading(false)
      })
      .catch((error) => {
        setIsLoading(false)
      })
  }

  function handleDeploymentFilter(value) {
    setDeploymentFilterType(value)
    setIsLoading(true)

    if (
      value === ASSIGNED_DEPLOYMENT_TYPE_FILTERS_OPTIONS.MY_OPEN_DEPLOYMENTS
    ) {
      getDeployments(STATUS.OPEN)
    } else if (
      value ===
      ASSIGNED_DEPLOYMENT_TYPE_FILTERS_OPTIONS.MY_COMPLETED_DEPLOYMENTS
    ) {
      getDeployments(STATUS.COMPLETED)
    }
    setIsLoading(false)
  }

  return (
    <React.Fragment>
      <Helmet title="Assigned Assessments" />
      <div>
        <MuiThemeProvider theme={muiTheme}>
          {isLoading ? (
            <CircularLoading />
          ) : (
            <div>
              <br />
              <Grid container>
                <Grid item xs={2} className={classes.margin13}>
                  <FormControl fullWidth>
                    <Select
                      className={classes.dropdownSize}
                      labelId="deploymentFilterType-select-label"
                      id="deploymentFilterType-select"
                      name="deploymentFilterType"
                      value={deploymentFilterType}
                      onChange={(e) => handleDeploymentFilter(e.target.value)}
                    >
                      {ASSIGNED_DEPLOYMENT_TYPE_FILTERS.map((d, index) => {
                        return (
                          <MenuItem
                            className={classes.dropdownSize}
                            key={index}
                            value={d}
                          >
                            {d}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <br />
              <Grid container>
                <Grid item xs={11}>
                  <Typography variant="h4" className={classes.margin13}>
                    Assigned Persistent Assessments
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                {PersistentSurveyData.map((persistent, index) => {
                  return (
                    <Grid item key={index}>
                      <Card className={classes.root}>
                        <Collapse
                          style={{ backgroundColor: '#aaa' }}
                          collapsedHeight={'5.6em'}
                          timeout="auto"
                          title={persistent.name}
                        >
                          <CardContent>
                            <Typography
                              variant="h1"
                              style={{
                                color: '#333',
                                fontSize: '17px',
                                fontWeight: 'bold',
                              }}
                            >
                              {persistent.name !== null &&
                              persistent.name !== undefined &&
                              persistent.name !== ''
                                ? persistent.name.length > 70
                                  ? persistent.name.slice(0, 70) + '...'
                                  : persistent.name
                                : ''}
                            </Typography>
                          </CardContent>
                        </Collapse>
                        <CardContent
                          className={
                            persistent.status === STATUS.COMPLETED
                              ? classes.cardContent1
                              : classes.cardContent2
                          }
                        >
                          <Typography className={classes.title}>
                            Deployment Id: {persistent.id}
                          </Typography>
                          <Typography className={classes.title}>
                            Type: {persistent.deployment_type}
                          </Typography>
                          <Typography className={classes.title}>
                            Status: {persistent.status}
                          </Typography>
                          <Typography className={classes.title}>
                            Created on:{' '}
                            {persistent.created_date === null
                              ? ''
                              : formatAMAndPM(
                                  new Date(
                                    formatedDateTime(
                                      new Date(persistent.created_date),
                                    ) + timeZone,
                                  ),
                                )}
                          </Typography>
                          <Typography className={classes.title}>
                            Created by: {persistent.created_by}
                          </Typography>
                          <Typography className={classes.title}>
                            Modified on:{' '}
                            {persistent.modified_date === null
                              ? ''
                              : formatAMAndPM(
                                  new Date(
                                    formatedDateTime(
                                      new Date(persistent.modified_date),
                                    ) + timeZone,
                                  ),
                                )}
                          </Typography>
                          <Typography className={classes.title}>
                            Modified by: {persistent.modified_by}
                          </Typography>
                        </CardContent>
                        <CardActions
                          className={
                            persistent.status === STATUS.COMPLETED
                              ? classes.cardContent1
                              : classes.cardContent2
                          }
                        >
                          {persistent.status === STATUS.COMPLETED ? (
                            ''
                          ) : (
                            <CustomTooltip title={'New'}>
                              <Link
                                color={'primary'}
                                to={{
                                  pathname: '/surveyAnswerUrl/' + persistent.id,
                                }}
                                style={{ textDecoration: 'none' }}
                              >
                                <AddCircleIcon className={classes.iconSize} />
                              </Link>
                            </CustomTooltip>
                          )}

                          <CustomTooltip title={'viewRecords'}>
                            <Link
                              color={'primary'}
                              to={{
                                pathname:
                                  '/surveyAnswers/' +
                                  persistent.id +
                                  '/' +
                                  persistent.name,
                              }}
                              style={{ textDecoration: 'none' }}
                            >
                              <PollIcon className={classes.iconSize} />
                            </Link>
                          </CustomTooltip>
                        </CardActions>
                      </Card>
                    </Grid>
                  )
                })}
              </Grid>
              <Grid container>
                <Grid item xs={11}>
                  <Typography
                    variant="h4"
                    className={classes.margin25}
                  ></Typography>
                </Grid>
              </Grid>
              <MUIDataGrid
                title="Assigned One Time Assessments"
                rows={oneTimeSurveyData.sort((a, b) =>
                  a.status === STATUS.LOCKED ? 1 : -1,
                )}
                columns={SURVEY_COLUMNS}
                options={options}
              />
            </div>
          )}
        </MuiThemeProvider>
      </div>
    </React.Fragment>
  )
}

export default withStyles(styles)(Survey)
