import React, { Component } from 'react'

import * as XLSX from 'xlsx'

import { Tabulator } from 'survey-analytics/survey.analytics.tabulator.js'
import * as Survey from 'survey-react'
import 'survey-analytics/survey.analytics.tabulator.css'
import 'tabulator-tables/dist/css/tabulator.min.css'

window.XLSX = XLSX

export default class SurveyAnalyticsTabulator extends Component {
  visPanel
  componentDidMount() {
    var options = {
      haveCommercialLicense: true, //Add this line
    }
    const { formData, surveyData, env } = this.props
    const survey = new Survey.SurveyModel(formData)
    surveyData.forEach((element) => {
      for (var key in element) {
        var fileData = element[key]
        if (
          fileData != null &&
          fileData.length > 0 &&
          fileData[0].type !== undefined &&
          fileData[0].name !== undefined &&
          element.deployment_mode !== undefined &&
          fileData[0].content !== undefined
        ) {
          element[key] = fileData.map((f) => {
            return {
              name: f.name,
              type: f.type,
              mode: element.deployment_mode,
              content:
                env?.applicationHost +
                '/' +
                f.content +
                '/deploymentMode/' +
                element.deployment_mode,
            }
          })
        }
      }
    })
    this.visPanel = new Tabulator(survey, surveyData, options)
    this.visPanel.render(document.getElementById('summaryContainer'))
  }
  render() {
    return (
      <React.Fragment>
        <div id="summaryContainer"></div>
      </React.Fragment>
    )
  }
}
