import { useContext, useEffect, useState } from 'react'

import { useNavigate } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'

import StoreIcon from '@material-ui/icons/LocalShipping'

import { useAuth } from '@praxis/component-auth'
import { ApplicationContext } from '../components/contexts/ApplicationContext'
import { getAllRoleIdentities } from '../utilities/restService'
import CircularLoading from './CircularLoading'
import AccessDeniedPage from './AccessDeniedPage'

const styles = (_) => ({
  icon: {
    fontSize: '10em',
  },
  card: {
    width: '14em',
    height: '100%',
    marginLeft: 20,
    marginTop: 10,
  },
  cardButton: {
    height: 'inherit',
  },
  cardContent: {
    height: 'inherit',
    textAlign: 'center',
  },
  drawerPaper: {
    padding: 40,
    paddingTop: 80,
    width: 425,
  },
})

const LandingPage = (props) => {
  const { classes } = props
  const auth = useAuth() // connect to the \`AuthContext\` provided by \`App\` below, using a React hook.
  const { session } = auth // assign variables of same name as properties off of \`auth\`
  const { userInfo } = session
  let navigate = useNavigate()

  const { setAppId } = useContext(ApplicationContext)
  const [roleIdentities, setRoleIdentities] = useState([])
  const [loading, setLoading] = useState(false)

  function getDistinctRecordsByAppId(records) {
    const distinctRecords = []
    const uniqueAppIds = new Set()

    records.forEach((record) => {
      if (!uniqueAppIds.has(record.application_id)) {
        uniqueAppIds.add(record.application_id)
        distinctRecords.push(record)
      }
    })

    return distinctRecords
  }

  useEffect(() => {
    setAppId(0)
    if (roleIdentities.length === 0) {
      setLoading(true)
      getAllRoleIdentities()
        .then((res) => {
          if (res.status === 200) {
            var result = res?.data.filter((r) =>
              userInfo?.memberOf.includes(r.ad_group_name?.toUpperCase()),
            )
            const distinctRecords = getDistinctRecordsByAppId(result)
            setRoleIdentities(distinctRecords)
            setLoading(false)
          }
        })
        .catch((error) => {
          setLoading(false)
        })
    }
  }, [setAppId, userInfo?.memberOf, roleIdentities.length])

  const applicationChange = (appId) => {
    setAppId(appId)
    navigate('/application/' + appId + '/assessments')
  }

  return (
    <div>
      {loading ? (
        <CircularLoading />
      ) : (
        <>
          {roleIdentities.length === 0 ? (
            <AccessDeniedPage />
          ) : (
            <>
              {roleIdentities.length === 1 ? (
                applicationChange(roleIdentities[0].application_id)
              ) : (
                <Grid
                  container
                  spacing={16}
                  justify="flex-start"
                  alignItems="stretch"
                  style={{ width: 'calc(100vw - 425px)', paddingLeft: 25 }}
                >
                  {roleIdentities.map((r) => {
                    return (
                      <Grid item>
                        <Card
                          className={classes.card}
                          data-testid={r.application_name + r.application_id}
                          onClick={() => applicationChange(r.application_id)}
                        >
                          <CardActionArea
                            className={classes.cardButton}
                            data-testid={
                              r.application_name + r.application_id + 'action'
                            }
                          >
                            <CardContent className={classes.cardContent}>
                              <StoreIcon
                                color="primary"
                                className={classes.icon}
                              />
                              <Typography variant="h5">
                                {r.application_name}
                              </Typography>
                            </CardContent>
                          </CardActionArea>
                        </Card>
                      </Grid>
                    )
                  })}
                </Grid>
              )}
            </>
          )}
        </>
      )}
    </div>
  )
}
export default withStyles(styles)(LandingPage)
