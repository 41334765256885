import React from 'react'
import { useAuth } from '@praxis/component-auth'
import * as Survey from 'survey-react'
import * as widgets from 'surveyjs-widgets'
import 'survey-react/survey.css'

import 'jquery-ui/themes/base/all.css'
import 'nouislider/distribute/nouislider.css'
import 'select2/dist/css/select2.css'
import 'bootstrap-slider/dist/css/bootstrap-slider.css'

import 'jquery-bar-rating/dist/themes/css-stars.css'

import $ from 'jquery'
import 'jquery-ui/ui/widgets/datepicker.js'
import 'select2/dist/js/select2.js'
import 'jquery-bar-rating'

import 'pretty-checkbox/dist/pretty-checkbox.css'

import { useEnv } from '@praxis/component-runtime-env'

window['$'] = window['jQuery'] = $

Survey.StylesManager.applyTheme('default')

widgets.prettycheckbox(Survey)
widgets.select2(Survey, $)
widgets.inputmask(Survey)
widgets.jquerybarrating(Survey, $)
widgets.jqueryuidatepicker(Survey, $)
widgets.nouislider(Survey)
widgets.select2tagbox(Survey, $)
widgets.sortablejs(Survey)
widgets.ckeditor(Survey)
widgets.autocomplete(Survey, $)
widgets.bootstrapslider(Survey)

export function CustomQuestions(props) {
  const auth = useAuth() // connect to the \`AuthContext\` provided by \`App\` below, using a React hook.
  const { session } = auth // assign variables of same name as properties off of \`auth\`
  const env = useEnv()

  Survey.ChoicesRestfull.onBeforeSendRequest = function (sender, options) {
    options.request.setRequestHeader(
      'Authorization',
      session?.userInfo?.accessToken,
    )
    options.request.setRequestHeader('x-api-key', env?.key)
  }
  if (
    Survey.ComponentCollection.Instance.getCustomQuestionByName('workday') ===
    null
  ) {
    Survey.ComponentCollection.Instance.add({
      name: 'Workday',
      category: 'Custom',
      elementsJSON: [
        {
          type: 'text',
          name: 'workday',
          title: 'Email',
        },
        {
          type: 'html',
          name: 'requesting',
          html: 'The data is requesting',
          visibleIf: '{email_request_processing} = true',
        },
        {
          type: 'html',
          name: 'error',
          html: '<div style=color:red><b>Please type the email correctly</b></div>',
          visibleIf: '{email_request_error} = true',
        },
      ],
    })
  }

  if (
    Survey.ComponentCollection.Instance.getCustomQuestionByName('location') ===
    null
  ) {
    Survey.ComponentCollection.Instance.add({
      name: 'Location',
      category: 'Custom',
      elementsJSON: [
        {
          type: 'dropdown',
          name: 'location',
          isRequired: true,
          renderAs: 'select2',
          choicesByUrl: {
            valueName: 'location_id',
            titleName: 'location_id',
          },
        },
        { type: 'text', name: 'region', readOnly: true, visible: false },
        { type: 'text', name: 'group', readOnly: true, visible: false },
        { type: 'text', name: 'district', readOnly: true, visible: false },
        { type: 'text', name: 'state', readOnly: true, visible: false },
      ],
    })
  }

  return <React.Fragment></React.Fragment>
}
