export const CURRENT_URL = {
  ACCESS:
    window.location.href.includes('localhost') ||
    window.location.href.includes('dev') ||
    window.location.href.includes('prod'),
}

export const DEPLOYMENT_MODE_OPTIONS = ['Vendor Facing', 'Internal']

export const DEPLOYMENT_AUDIENCE_OPTIONS = [
  'Open Deployment',
  'Assigned Deployments',
]

export const DEPLOYMENT_TYPE_OPTIONS = ['One Time', 'Persistent']

export const LOCKING_DURATION_FILTERS = [
  'Immediately',
  '24 hours',
  '72 hours',
  'Never',
]

export const ASSIGNED_DEPLOYMENT_TYPE_FILTERS = ['Open', 'Completed']

export const DEPLOYMENT_TYPE_FILTERS = [
  'My Open Deployments',
  'My Completed Deployments',
  'All Deployments',
]

export const ASSESSMENT_TYPE_FILTERS = [
  'My Admin Assessments',
  'All Admin Assessments',
]

export const DEPLOYMENT_MODE_VALUES = {
  VENDOR_FACING: 'Vendor Facing',
  INTERNAL: 'Internal',
}

export const DEPLOYMENT_AUDIENCE_VALUES = {
  OPEN: 'Open Deployment',
  ASSIGNED: 'Assigned Deployments',
}

export const DEPLOYMENT_TYPE_VALUES = {
  ONETIME: 'One Time',
  PERSISTENT: 'Persistent',
}

export const STATUS = {
  COMPLETED: 'Completed',
  OPEN: 'Open',
  ALL: 'all',
  LOCKED: 'Locked',
}

export const ASSESSMENT_OWNER = {
  OWN: 'own',
  ALL: 'all',
}

export const ASSIGNED_DEPLOYMENT_TYPE_FILTERS_OPTIONS = {
  MY_OPEN_DEPLOYMENTS: 'Open',
  MY_COMPLETED_DEPLOYMENTS: 'Completed',
}

export const DEPLOYMENT_TYPE_FILTERS_OPTIONS = {
  MY_OPEN_DEPLOYMENTS: 'My Open Deployments',
  MY_COMPLETED_DEPLOYMENTS: 'My Completed Deployments',
  ALL_DEPLOYMENTS: 'All Deployments',
}

export const ASSESSMENT_TYPE_FILTER_OPTIONS = {
  MY_ADMIN_ASSESSMENTS: 'My Admin Assessments',
  ALL_ASSESSMENTS: 'All Admin Assessments',
}

export const LOCKING_DURATION_FILTER_OPTIONS = {
  NOW: 'Immediately',
  ONE_DAY: '24 hours',
  THREE_DAYS: '72 hours',
  NEVER: 'Never',
}

export const LOCKING_DURATION_VALUES = {
  NOW: 0,
  ONE_DAY: 24,
  THREE_DAYS: 72,
  NEVER: -1,
}

export const DEFAULT_ERROR_MESSAGE =
  'Something went wrong, please try again after sometime.'

export const ROLE = {
  ADMIN: 'admin',
  READ: 'read',
}
