import { Quill } from 'react-quill'
import ImageResize from 'quill-image-resize-module-react'

// Custom Undo button icon component for Quill editor. You can import it directly
// from 'quill/assets/icons/undo.svg' but a number of loaders do not
// handle them correctly

const CustomUndo = () => (
  <svg viewBox="0 0 18 18">
    <polygon className="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10" />
    <path
      className="ql-stroke"
      d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"
    />
  </svg>
)

// Redo button icon component for Quill editor
const CustomRedo = () => (
  <svg viewBox="0 0 18 18">
    <polygon className="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10" />
    <path
      className="ql-stroke"
      d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"
    />
  </svg>
)

// Undo and redo functions for Custom Toolbar
function undoChange() {
  this.quill.history.undo()
}
function redoChange() {
  this.quill.history.redo()
}

// Add sizes to whitelist and register them
const Size = Quill.import('attributors/style/size')
Size.whitelist = [
  '10px',
  '11px',
  '12px',
  '14px',
  '16px',
  '18px',
  '20px',
  '22px',
  '24px',
  '26px',
  '28px',
  '36px',
  '48px',
]
Quill.register(Size, true)

// Add fonts to whitelist and register
const Font = Quill.import('attributors/style/font')
Font.whitelist = [
  'Arial',
  'Comic Sans MS',
  'Courier New',
  'Georgia',
  'Lucida Sans Unicode',
  'Tahoma',
]
Quill.register(Font, true)

Quill.register('modules/imageResize', ImageResize)
// Modules object for setting up the Quill editor
export const modules = {
  toolbar: {
    container: '#toolbar',
    handlers: {
      undo: undoChange,
      redo: redoChange,
    },
  },
  history: {
    delay: 500,
    maxStack: 100,
    userOnly: true,
  },
  imageResize: {
    parchment: Quill.import('parchment'),
    modules: ['Resize', 'DisplaySize'],
  },
}

// Formats objects for setting up the Quill editor
export const formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'script',
  'background',
  'link',
  'image',
  'color',
]

// Quill Toolbar component
export const QuillToolbar = () => {
  return (
    <div id="toolbar">
      <span className="ql-formats">
        <select className="ql-font" defaultValue="Arial">
          <option value="Arial">Arial</option>
          <option value="Comic Sans MS">Comic-sans</option>
          <option value="Courier New">Courier New</option>
          <option value="Georgia">Georgia</option>
          <option value="Lucida Sans Unicode">Lucida Sans</option>
          <option value="Tahoma">Tahoma</option>
        </select>
        <select className="ql-size" defaultValue="12px">
          <option value="10px">10</option>
          <option value="11px">11</option>
          <option value="12px">12</option>
          <option value="14px">14</option>
          <option value="16px">16</option>
          <option value="18px">18</option>
          <option value="20px">20</option>
          <option value="22px">22</option>
          <option value="24px">24</option>
          <option value="26px">26</option>
          <option value="28px">28</option>
          <option value="36px">36</option>
          <option value="48px">48</option>
        </select>
        <select className="ql-header" defaultValue="3">
          <option value="1">Heading</option>
          <option value="2">Subheading</option>
          <option value="3">Normal</option>
        </select>
      </span>
      <span className="ql-formats">
        <button
          class="ql-bold"
          data-toggle="tooltip"
          data-placement="bottom"
          title="Bold"
        />
        <button
          class="ql-italic"
          data-toggle="tooltip"
          data-placement="bottom"
          title="Italics"
        />
        <button
          class="ql-underline"
          data-toggle="tooltip"
          data-placement="bottom"
          title="Underline"
        />
        <button
          class="ql-strike"
          data-toggle="tooltip"
          data-placement="bottom"
          title="Strikethrough"
        />
      </span>
      <span className="ql-formats">
        <button
          className="ql-script"
          value="super"
          data-toggle="tooltip"
          data-placement="bottom"
          title="Superscript"
        />
        <button
          className="ql-script"
          value="sub"
          data-toggle="tooltip"
          data-placement="bottom"
          title="Subscript"
        />
      </span>
      <span className="ql-formats">
        <select
          className="ql-color"
          data-toggle="tooltip"
          data-placement="bottom"
          title="Font Color"
        />
        <select
          className="ql-background"
          data-toggle="tooltip"
          data-placement="bottom"
          title="Highlight Color"
        />
      </span>
      <span className="ql-formats">
        <button
          className="ql-link"
          data-toggle="tooltip"
          data-placement="bottom"
          title="Insert link"
        />
        <button
          className="ql-image"
          data-toggle="tooltip"
          data-placement="bottom"
          title="Insert Image"
        />
      </span>
      <span className="ql-formats">
        <button
          className="ql-clean"
          data-toggle="tooltip"
          data-placement="bottom"
          title="Clear Formatting"
        />
      </span>
      <span className="ql-formats">
        <button
          className="ql-undo"
          data-toggle="tooltip"
          data-placement="bottom"
          title="Undo"
        >
          <CustomUndo />
        </button>
        <button
          className="ql-redo"
          data-toggle="tooltip"
          data-placement="bottom"
          title="Redo"
        >
          <CustomRedo />
        </button>
      </span>
    </div>
  )
}

export default QuillToolbar
