export const routes = {
  app: {
    index: '/',
    assessments: '/application/:appId/assessments',
    update: '/application/:appId/update/:id',
    creator: '/application/:appId/creator',
    image: '/surveyImages/:imageName/deploymentMode/:deploymentMode',
    analytics: '/application/:appId/analytics/:id',
    analyticstabulator: '/application/:appId/analyticstabulator/:id',
    deploymentCreatorByFormId: '/application/:appId/createDeployment/:formId',
    deploymentCreator: '/application/:appId/deploymentCreator',
    deploymentUpdate: '/application/:appId/deploymentUpdate/:deployId',
    deployments: '/application/:appId/deployments',
    groupCreator: '/groupCreator',
    groupUpdate: '/groupUpdate/:id',
    groups: '/groups',
    surveys: '/surveys',
    surveyAnswerUrl: '/surveyAnswerUrl/:deploymentId',
    surveyAnswerUpdateUrl:
      '/surveyAnswerUpdateUrl/:deploymentId/answers/:answerId',
    surveyAnswerViewUrl:
      '/surveyAnswerUpdateUrl/:deploymentId/viewAnswers/:answerId',
    surveyAnswers: '/surveyAnswers/:deploymentId/:deploymentName',
    export: '/export',
  },
}
export default routes
