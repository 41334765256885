import PropTypes from 'prop-types'

import withStyles from '@material-ui/core/styles/withStyles'
import CircularProgress from '@material-ui/core/CircularProgress'

const styles = (theme) => ({
  spanLoading: {
    justifyContent: 'center',
    top: '50%',
  },
  divLoading: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    height: '85vh',
    width: '100vw',
  },
})

const CircularLoading = ({ classes }) => (
  <div className={classes.divLoading}>
    <CircularProgress />
    <span className={classes.spanLoading}>Loading...please wait</span>
  </div>
)

CircularLoading.propTypes = {
  classes: PropTypes.object,
}

export default withStyles(styles)(CircularLoading)
