import { useState, createContext } from 'react'

export const ApplicationContext = createContext()

function ApplicationContextProvider(props) {
  const [appId, setAppId] = useState(0)
  const [roleIdentities, setRoleIdentities] = useState([])

  const value = { appId, setAppId, setRoleIdentities, roleIdentities }
  return (
    <ApplicationContext.Provider value={value}>
      {props.children}
    </ApplicationContext.Provider>
  )
}

export default ApplicationContextProvider
