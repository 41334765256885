import React, { useState, useEffect, useContext } from 'react'
import SurveyAnalyticsTabulator from './SurveyAnalyticsTabulator'
import {
  getSurveryResponseDataByDeploymentId,
  getFormByDeploymentId,
  deleteSurveyAnswerById,
  downloadFileBulk,
} from '../../utilities/restService'
import { CustomQuestions } from '../surveyAnswer/CustomQuestions'
import { useEnv } from '@praxis/component-runtime-env'
import { formatAMAndPM } from '../../utilities/date'
import {
  TableExtensions,
  DocumentHelper,
} from 'survey-analytics/survey.analytics.tabulator.js'
import muiTheme from '../../config/themeConfig'
import { confirmAlert } from 'react-confirm-alert' //Import
import 'react-confirm-alert/src/react-confirm-alert.css' //Importcss
import { ApplicationContext } from '../contexts/ApplicationContext'
import { useParams } from 'react-router-dom'
import { ROLE } from '../../utilities/constants'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import { makeStyles } from '@material-ui/core/styles'
import { FormControl, IconButton, CircularProgress } from '@material-ui/core'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import CustomTooltip from '../../shared/CustomTooltip'
import Select from '@material-ui/core/Select'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import CustomizedSnackbars from '../../shared/CustomizedSnackbars'

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    maxWidth: 330,
    minWidth: 330,
    marginLeft: 13,
    marginBottom: 20,
  },
}))

export function AnalyticsTabulatorPage(props) {
  const env = useEnv()
  const [isFormLoading, setIsFormLoading] = useState(false)
  const [formData, setFormData] = useState({})
  const [isSurveyLoading, setIsSurveyLoading] = useState(false)
  const [surveyData, setSurveyData] = useState([])
  const { setAppId, roleIdentities } = useContext(ApplicationContext)
  const [fileColumns, setFileColumns] = useState([])
  const [selectedFile, setSelectedFile] = useState('')
  const [deploymentMode, setDeploymentMode] = useState('')
  const [isDownloading, setIsDownloading] = useState(false)
  const [message, setMessage] = useState('')
  const [isSuccess, setIsSuccess] = useState(false)
  const [isError, setIsError] = useState(false)
  let { appId, id } = useParams()

  const classes = useStyles()

  const handleButtonClick = async () => {
    setIsDownloading(true)

    var fileName =
      'Files of ' + id + ' ' + fileColumns.get(selectedFile) + '.zip'
    const request = {
      file_name: fileName,
      field: selectedFile,
      deployment_mode: deploymentMode,
      deployment_id: id,
    }

    try {
      const fileRes = await downloadFileBulk(request)
      console.log('RESPONSE 1')
      console.log(fileRes)
      if (fileRes?.status === 200) {
        console.log('fn')
        console.log(request)
        var contentType = 'application/octet-stream'
        var a = document.createElement('a')
        var blob = new Blob([fileRes.data], { type: contentType })
        a.href = window.URL.createObjectURL(blob)
        a.download = request.file_name
        a.click()
        setMessage('Download Successful')
        setIsSuccess(true)
      } else {
        setMessage('Failed to download file: ' + fileRes.statusText)
        setIsError(true)
      }
    } catch (error) {
      setMessage(error.message || 'Failed to download file')
      setIsError(true)
    }
    setIsDownloading(false)
  }

  var filterRoles = roleIdentities
    ?.filter((r) => r.application_id.toString() === appId.toString())
    .map((r) => r.role)

  useEffect(() => {
    if (appId !== undefined) {
      setAppId(appId)
    }
  }, [appId, setAppId])

  useEffect(() => {
    function getFormDataByDeploymentId() {
      if (id !== undefined) {
        setIsFormLoading(true)
        getFormByDeploymentId(id)
          .then((res) => {
            if (res.data?.survey_form_json?.pages.length > 0) {
              var obj1 = {
                type: 'text',
                name: 'response_id',
                title: 'Response Id',
              }
              var obj2 = {
                type: 'text',
                name: 'response_status',
                title: 'Response Status',
              }

              var obj3 = {
                type: 'text',
                name: 'submitted_by',
                title: 'Submitted By',
              }
              var obj4 = {
                type: 'text',
                name: 'submitted_date',
                title: 'Submitted Date (UTC Format)',
              }

              res.data?.survey_form_json?.pages[0].elements.unshift(obj4)
              res.data?.survey_form_json?.pages[0].elements.unshift(obj3)
              res.data?.survey_form_json?.pages[0].elements.unshift(obj2)
              res.data?.survey_form_json?.pages[0].elements.unshift(obj1)

              setFormData(res.data?.survey_form_json)
            }

            const elements = res.data?.survey_form_json?.pages[0].elements
            setDeploymentMode(res.data?.deployment_mode)
            let fileElementMap = new Map()

            const extractFileNames = (element) => {
              // Base case: If element itself is of type 'file'
              if (element.type === 'file') {
                const name = element.name
                const title = element.title || name // Use title if available, otherwise use name
                fileElementMap.set(name, title)
              }

              // Recursive case: Check if element has any property that might contain nested elements
              Object.values(element).forEach((value) => {
                if (Array.isArray(value)) {
                  value.forEach((subElement) => extractFileNames(subElement))
                }
              })
            }

            elements.forEach((element) => extractFileNames(element)) // Start the recursion
            setFileColumns(fileElementMap)

            setIsFormLoading(false)
          })
          .catch((error) => {
            setIsFormLoading(false)
          })
      }
    }

    function getSurveyResponseByDeploymentId() {
      if (id !== undefined) {
        setIsSurveyLoading(true)
        getSurveryResponseDataByDeploymentId(id)
          .then((res) => {
            setSurveyData(
              res.data.map((s) => {
                s.survey_answer_json.response_id = s.id
                s.survey_answer_json.response_status = s.status
                s.survey_answer_json.submitted_by = s.submitted_by
                s.survey_answer_json.deployment_mode = s.deployment_mode
                s.survey_answer_json.submitted_date =
                  s.submitted_date === null
                    ? ''
                    : formatAMAndPM(new Date(s.submitted_date))
                return s.survey_answer_json
              }),
            )
            setIsSurveyLoading(false)
          })
          .catch((error) => {
            setIsSurveyLoading(false)
          })
      }
    }
    if (Object.keys(formData).length === 0) {
      getFormDataByDeploymentId()
      getSurveyResponseByDeploymentId()
    }
  }, [id, formData])

  function refreshPage() {
    window.location.reload(false)
  }

  function updateResponseId(responseId) {
    if (id !== undefined) {
      var url = '/surveyAnswerUpdateUrl/' + id + '/answers/' + responseId
      window.open(url, '_blank')
    }
  }

  function viewResponseId(responseId) {
    if (id !== undefined) {
      var url = '/surveyAnswerUpdateUrl/' + id + '/viewAnswers/' + responseId
      window.open(url, '_blank')
    }
  }

  function deleteResponseId(responseId) {
    confirmAlert({
      title: 'Assessment Responses',
      message:
        'Are you sure you want to delete this response id:' + responseId + '?',
      closeOnEscape: false,
      closeOnClickOutside: false,
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            deleteSurveyAnswerById(responseId)
              .then((res) => {
                refreshPage()
              })
              .catch((error) => {})
          },
        },
        {
          label: 'No',
          onClick: () => {
            return false
          },
        },
      ],
    })
  }

  if (
    filterRoles?.includes(ROLE.ADMIN) &&
    TableExtensions.findExtension('details', 'delete') === null &&
    surveyData.length > 0
  ) {
    TableExtensions.registerExtension({
      location: 'details',
      name: 'delete',
      visibleIndex: 1,
      render: (table, opt) => {
        const btn = DocumentHelper.createElement('button', 'sa-table__btn', {
          innerHTML: 'DeleteResult',
          onclick: (e) => {
            e.stopPropagation()
            deleteResponseId(opt.row.innerRow._row.data.response_id)
          },
        })
        return btn
      },
    })
  }

  if (TableExtensions.findExtension('details', 'update') !== null) {
    TableExtensions.unregisterExtension('details', 'update')
  }

  if (
    filterRoles?.includes(ROLE.ADMIN) &&
    TableExtensions.findExtension('details', 'update') === null &&
    surveyData.length > 0
  ) {
    TableExtensions.registerExtension({
      location: 'details',
      name: 'update',
      visibleIndex: 1,
      render: (table, opt) => {
        const btn = DocumentHelper.createElement('button', 'sa-table__btn', {
          innerHTML: 'UpdateResult',
          onclick: (e) => {
            e.stopPropagation()
            updateResponseId(opt.row.innerRow._row.data.response_id)
          },
        })
        return btn
      },
    })
  }

  if (
    TableExtensions.findExtension('details', 'view') === null &&
    surveyData.length > 0
  ) {
    TableExtensions.registerExtension({
      location: 'details',
      name: 'view',
      visibleIndex: 1,
      render: (table, opt) => {
        const btn = DocumentHelper.createElement('button', 'sa-table__btn', {
          innerHTML: 'ViiewResult',
          onclick: (e) => {
            e.stopPropagation()
            viewResponseId(opt.row.innerRow._row.data.response_id)
          },
        })
        return btn
      },
    })
  }

  return (
    <React.Fragment>
      {isSuccess ? (
        <CustomizedSnackbars variant="success" message={message} />
      ) : isError ? (
        <CustomizedSnackbars variant="error" message={message} />
      ) : (
        ''
      )}
      <div style={{ margin: '20px' }}>
        <h3>Field assessment results in the table form</h3>

        {!isFormLoading && !isSurveyLoading && appId !== 0 ? (
          <div>
            <MuiThemeProvider theme={muiTheme}>
              <div
                style={{ display: 'flex', alignItems: 'center', gap: '10px' }}
              >
                <FormControl className={classes.formControl}>
                  <InputLabel id="field-bulk-download">
                    Select an Attachment Field
                  </InputLabel>
                  <CustomTooltip
                    title={'For Bulk Download of files in a Column'}
                    placement="top"
                  >
                    <Select
                      labelId="field-bulk-download"
                      value={selectedFile}
                      onChange={(e) => setSelectedFile(e.target.value)}
                      disabled={isDownloading}
                    >
                      {[...fileColumns.entries()].map(([name, title]) => {
                        return (
                          <MenuItem key={name} value={name}>
                            {title}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </CustomTooltip>
                </FormControl>
                <CustomTooltip title={'Download all files in selected field'}>
                  <IconButton
                    variant="contained"
                    style={{ color: '#8c1f27', fontSize: '4rem' }}
                    onClick={handleButtonClick}
                    disabled={isDownloading || !selectedFile}
                  >
                    <CloudDownloadIcon
                      style={{
                        fontSize: '4rem',
                        color:
                          isDownloading || !selectedFile
                            ? '#808080'
                            : '#8c1f27',
                      }}
                    />
                  </IconButton>
                </CustomTooltip>
                <div>
                  {isDownloading && (
                    <div>
                      <span>Downloading...</span>
                      <CircularProgress size={15} />
                    </div>
                  )}
                </div>
              </div>
            </MuiThemeProvider>
            <CustomQuestions />
            <SurveyAnalyticsTabulator
              formData={formData}
              surveyData={surveyData}
              env={env}
            />
          </div>
        ) : (
          'loading ....'
        )}
      </div>
    </React.Fragment>
  )
}
