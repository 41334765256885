import React, { useContext, useEffect, useState } from 'react'
import SurveyCreator from './SurveyCreator'
import 'bootstrap/dist/css/bootstrap.css'
import { useAuth } from '@praxis/component-auth'
import { ApplicationContext } from '../contexts/ApplicationContext'
import CircularLoading from '../../shared/CircularLoading'
import { useNavigate, useParams } from 'react-router-dom'

export function CreatorPage(props) {
  const auth = useAuth() // connect to the \`AuthContext\` provided by \`App\` below, using a React hook.
  const { session } = auth // assign variables of same name as properties off of \`auth\`

  let navigate = useNavigate()
  let { id, appId } = useParams()

  const { setAppId } = useContext(ApplicationContext)
  const [createTitle, setCreateTitle] = useState('Create a field assessment')

  useEffect(() => {
    if (id !== undefined) {
      setCreateTitle('Update a field assessment')
    }
    if (appId !== undefined) {
      setAppId(appId)
    }
  }, [id, appId, setAppId, setCreateTitle])

  return (
    <React.Fragment>
      <div style={{ margin: '20px' }}>
        <h3>{createTitle}</h3>
        {appId === 0 ? (
          <CircularLoading />
        ) : (
          <SurveyCreator
            session={session}
            navigate={navigate}
            id={id}
            appId={appId}
            {...props}
          />
        )}
      </div>
    </React.Fragment>
  )
}
