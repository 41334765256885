import 'date-fns'
import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useNavigate, useParams } from 'react-router-dom'
import TextField from '@material-ui/core/TextField'
import withStyles from '@material-ui/core/styles/withStyles'
import { Grid, Typography, FormControl } from '@material-ui/core'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import muiTheme from '../../config/themeConfig'
import LoadingButton from '../../shared/LoadingButton'
import CustomizedSnackbars from '../../shared/CustomizedSnackbars'
import CircularLoading from '../../shared/CircularLoading'
import {
  saveCustomGroup,
  getCustomGroupById,
  updateCustomGroup,
  getAllUsers,
} from '../../utilities/restService'
import RecipientTable from './RecipientTable'
import UserTable from './UserTable'

const styles = (theme) => ({
  formControl: {
    margin: theme.spacing(1),

    maxWidth: 330,
    minWidth: 330,
    marginLeft: 13,
    marginBottom: 20,
  },
  dateFormControl: {
    margin: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
    paddingTop: '19px',
  },
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
  },
})

const CreateGroup = (props) => {
  const { classes } = props
  const [isSuccess, setIsSuccess] = useState(false)
  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isCircularLoading, setIsCircularLoading] = useState(false)
  const [groupName, setGroupName] = useState('')
  const [groupId, setGroupId] = useState('')
  const [message, setMessage] = useState('')
  const [teamMemberList, setTeamMemberList] = useState([])
  const [usersList, setUsersList] = useState([])
  let navigate = useNavigate()
  let { id } = useParams()
  useEffect(() => {
    setIsCircularLoading(true)
    getAllUsers()
      .then((res) => {
        if (res.data.users !== undefined) {
          setUsersList(res.data.users)
        }
        setIsCircularLoading(false)
      })
      .catch((error) => {
        setIsCircularLoading(false)
      })
  }, [])
  if (id !== undefined && groupId === '') {
    setGroupId(id)
    setIsLoading(true)
    getCustomGroupById(id)
      .then((res) => {
        if (res.data.name !== undefined) {
          setGroupName(res.data.name)
          setTeamMemberList(res.data.user_responses)
        }
        setIsLoading(false)
      })
      .catch((error) => {
        setIsLoading(false)
      })
  }

  async function createGroup() {
    var request = {
      name: groupName,
      user_requests: teamMemberList,
    }
    setIsSuccess(false)
    saveCustomGroup(request)
      .then((res) => {
        setIsLoading(false)
        setIsSuccess(true)
        setMessage('Group created successfully.')
        navigate('groupUpdate/' + res.data.custom_group_id)
      })
      .catch((error) => {
        setIsLoading(false)
      })
  }

  async function updateGroupById(id) {
    var request = {
      name: groupName,
      user_requests: teamMemberList,
    }
    setIsSuccess(false)
    updateCustomGroup(request, id)
      .then((res) => {
        setIsLoading(false)
        setIsSuccess(true)
        setMessage('Group updated successfully.')
      })
      .catch((error) => {
        setIsLoading(false)
      })
  }

  const handleGroup = () => {
    setIsLoading(true)
    setIsSuccess(false)
    groupId === '' ? createGroup() : updateGroupById(groupId)
  }

  const deleteRecipient = (recipientId) => {
    setTeamMemberList(teamMemberList.filter((t) => t.id !== recipientId))
  }

  const addSelectedUser = (selectedRows) => {
    var selectedUsers = selectedRows.data.map(function (obj) {
      var userdata = usersList[obj.dataIndex]
      if (userdata.email_id !== undefined) {
        var filteredTeamMemberData = teamMemberList.filter(
          (t) => t.email_id === userdata.email_id,
        )
        if (filteredTeamMemberData.length === 0) {
          return userdata
        }
      }
      return ''
    })
    var filtered = selectedUsers.filter((x) => x !== '')
    setTeamMemberList([...teamMemberList, ...filtered])
  }

  const addUser = (userId) => {
    setIsError(false)
    try {
      var userdata = usersList.filter((t) => t.id === userId)
      if (userdata[0].email_id !== undefined) {
        var filteredTeamMemberData = teamMemberList.filter(
          (t) => t.email_id === userdata[0].email_id,
        )
        if (filteredTeamMemberData.length === 0) {
          setTeamMemberList([...teamMemberList, userdata[0]])
        }
      } else {
        setIsError(true)
        setMessage('Please enter valid email id')
      }
    } catch (e) {
      if (e.response && e.response.data) {
        setIsError(true)
        setMessage(e?.response?.data?.message)
      }
    }
  }

  var isDisabled = false
  if (isLoading || groupName.trim() === '' || teamMemberList.length === 0) {
    isDisabled = true
  }

  return (
    <React.Fragment>
      <Helmet title="Create Group" />
      {isSuccess ? (
        <CustomizedSnackbars variant="success" message={message} />
      ) : isError ? (
        <CustomizedSnackbars variant="error" message={message} />
      ) : (
        ''
      )}
      <MuiThemeProvider theme={muiTheme}>
        <div className={classes.paper}>
          <Typography variant="h4">Group Details</Typography>
          <Grid container>
            {groupId === '' ? (
              ''
            ) : (
              <Grid item>
                <FormControl className={classes.formControl}>
                  <TextField
                    id="groupId"
                    label="Group Id"
                    name="groupId"
                    required
                    value={groupId}
                    disabled
                    onChange={(e) => setGroupId(e.target.value)}
                  />
                </FormControl>
              </Grid>
            )}

            <Grid item>
              <FormControl className={classes.formControl}>
                <TextField
                  id="groupName"
                  label="Group Name"
                  name="groupName"
                  required
                  value={groupName}
                  onChange={(e) => setGroupName(e.target.value)}
                />
              </FormControl>
            </Grid>
          </Grid>
          {isCircularLoading ? (
            <CircularLoading />
          ) : usersList.length > 0 ? (
            <UserTable
              usersList={usersList}
              addUser={addUser}
              addSelectedUser={addSelectedUser}
            />
          ) : (
            ''
          )}

          <br></br>
          {teamMemberList.length > 0 ? (
            <RecipientTable
              teamMemberList={teamMemberList}
              deleteRecipient={deleteRecipient}
            />
          ) : (
            ''
          )}

          <FormControl fullWidth className={classes.formControl}>
            <LoadingButton
              variant="contained"
              color="primary"
              loading={isLoading}
              disabled={isDisabled}
              onClick={handleGroup}
            >
              {groupId === '' ? 'Create' : 'Update'}
            </LoadingButton>
          </FormControl>
        </div>
      </MuiThemeProvider>
    </React.Fragment>
  )
}

export default withStyles(styles)(CreateGroup)
