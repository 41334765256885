import PropTypes from 'prop-types'

import withStyles from '@material-ui/core/styles/withStyles'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'

const styles = (theme) => ({
  wrapper: {
    position: 'relative',
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
})

const LoadingButton = ({
  classes,
  children,
  loading,
  buttonClasses,
  ...buttonProps
}) => (
  <div className={classes.wrapper}>
    <Button classes={buttonClasses} {...buttonProps}>
      {children}
    </Button>
    {loading && (
      <CircularProgress size={24} className={classes.buttonProgress} />
    )}
  </div>
)

LoadingButton.propTypes = {
  classes: PropTypes.object,
  loading: PropTypes.bool,
}

export default withStyles(styles)(LoadingButton)
