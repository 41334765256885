import React from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'
import { makeStyles } from '@material-ui/core/styles'

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  font15: {
    fontSize: 15,
  },
}))

const CustomizedSnackbars = (props) => {
  const { variant, message } = props
  const classes = useStyles()
  const [open, setOpen] = React.useState(true)

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div className={classes.root}>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {variant === 'success' ? (
          <Alert
            onClose={handleClose}
            severity="success"
            className={classes.font15}
          >
            {message}
          </Alert>
        ) : variant === 'error' ? (
          <Alert
            onClose={handleClose}
            severity="error"
            className={classes.font15}
          >
            {message}
          </Alert>
        ) : variant === 'warning' ? (
          <Alert
            onClose={handleClose}
            severity="warning"
            className={classes.font15}
          >
            {message}
          </Alert>
        ) : variant === 'info' ? (
          <Alert
            onClose={handleClose}
            severity="info"
            className={classes.font15}
          >
            {message}
          </Alert>
        ) : (
          ''
        )}
      </Snackbar>
    </div>
  )
}
export default CustomizedSnackbars
