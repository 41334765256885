import 'date-fns'
import React from 'react'
import { Helmet } from 'react-helmet'
import withStyles from '@material-ui/core/styles/withStyles'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import muiTheme from '../../config/themeConfig'
import MUIDataGrid from '../../shared/MUIDataGrid'
import CustomToolbarSelect from '../../shared/CustomToolbarSelect'
import AddIcon from '@material-ui/icons/Add'
const styles = (theme) => ({
  iconSize: {
    width: 25,
    height: 25,
    color: '#e21717',
    cursor: 'pointer',
  },
  margin13: {
    marginLeft: 13,
    marginTop: 4,
  },
})

const UserTable = (props) => {
  const { classes, usersList, addUser, addSelectedUser } = props
  // User Columns
  const USER_COLUMNS = [
    {
      name: 'id',
      label: 'Id',
      options: {
        display: false,
        filter: false,
      },
    },
    {
      name: 'lan_id',
      label: 'LanId',
      options: {
        filter: false,
      },
    },
    {
      name: 'first_name',
      label: 'First Name',
      options: {
        filter: false,
      },
    },
    {
      name: 'last_name',
      label: 'Last Name',
      options: {
        filter: false,
      },
    },
    {
      name: 'email_id',
      label: 'Email ID',
      options: {
        filter: false,
      },
    },
    {
      name: 'role',
      label: 'Role',
      options: {
        filter: true,
        filterType: 'multiselect',
      },
    },
    {
      name: 'location_id',
      label: 'Location ID',
      options: {
        filter: true,
        filterType: 'multiselect',
      },
    },
    {
      name: 'region',
      label: 'Region',
      options: {
        filter: true,
        filterType: 'multiselect',
      },
    },
    {
      name: 'location_group',
      label: 'Group',
      options: {
        filter: true,
        filterType: 'multiselect',
      },
    },
    {
      name: 'work_state',
      label: 'State',
      options: {
        filter: true,
        filterType: 'multiselect',
      },
    },
    {
      name: 'id',
      label: 'Actions',
      options: {
        filter: false,
        download: false,
        customBodyRender: (value) => (
          <AddIcon
            className={classes.iconSize}
            onClick={(e) => {
              e.preventDefault()
              addUser(value)
            }}
          />
        ),
      },
    },
  ]

  const options = {
    rowsPerPage: 5,
    filter: true,
    selectableRows: 'multiple',
    filterType: 'dropdown',
    responsive: 'standard',
    fixedHeader: true,
    tableBodyHeight: '35vh',
    rowsPerPageOptions: [5, 20, 50, 100],
    rowHover: true,
    sort: true,
    print: false,
    viewColumns: true,
    download: false,
    search: true,
    customToolbarSelect: (selectedRows) => (
      <CustomToolbarSelect
        selectedRows={selectedRows}
        addSelectedUser={addSelectedUser}
      />
    ),
  }

  return (
    <React.Fragment>
      <Helmet title="Create Group" />
      <div>
        <Grid container>
          <Grid item xs={11}></Grid>
          <Grid item />
        </Grid>
        <MuiThemeProvider theme={muiTheme}>
          <MUIDataGrid
            title="Worker api user data"
            rows={usersList}
            columns={USER_COLUMNS}
            options={options}
          />
        </MuiThemeProvider>
      </div>
    </React.Fragment>
  )
}

export default withStyles(styles)(UserTable)
