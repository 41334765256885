import * as SurveyKo from 'survey-knockout'
import * as Survey from 'survey-react'
import { Quill } from 'react-quill'

if (typeof SurveyKo !== 'undefined') {
  QuillEditortcustomwidget(SurveyKo, Quill)
}

if (typeof Survey !== 'undefined') {
  QuillEditortcustomwidget(Survey, Quill)
}

function QuillEditortcustomwidget(Survey, Quill) {
  var widget = {
    //the widget name. It should be unique and written in lowcase.
    name: 'quilleditor',
    //the widget title. It is how it will appear on the toolbox of the SurveyJS Editor/Builder
    title: 'Rich Text Editor',
    //the name of the icon on the toolbox. We will leave it empty to use the standard one
    iconName: '',
    //If the widgets depends on third-party library(s) then here you may check if this library(s) is loaded
    widgetIsLoaded: function () {
      //return typeof $ == "function" && !!$.fn.select2; //return true if jQuery and select2 widget are loaded on the page
      return typeof Quill !== 'undefined' //we do not require anything so we just return true.
    },
    //SurveyJS library calls this function for every question to check, if it should use this widget instead of default rendering/behavior
    isFit: function (question) {
      //we return true if the type of question is textwithbutton
      return question.getType() === 'quilleditor'
      //the following code will activate the widget for a text question with inputType equals to date
      //return question.getType() === 'text' && question.inputType === "date";
    },
    //Use this function to create a new class or add new properties or remove unneeded properties from your widget
    //activatedBy tells how your widget has been activated by: property, type or customType
    //property - it means that it will activated if a property of the existing question type is set to particular value, for example inputType = "date"
    //type - you are changing the behaviour of entire question type. For example render radiogroup question differently, have a fancy radio buttons
    //customType - you are creating a new type, like in our example "textwithbutton"
    activatedByChanged: function (activatedBy) {
      //we do not need to check acticatedBy parameter, since we will use our widget for customType only
      //We are creating a new class and derived it from text question type. It means that text model (properties and fuctions) will be available to us
      // Survey.JsonObject.metaData.addClass('textwithbutton', [], null, 'text')

      //signaturepad is derived from "empty" class - basic question class
      //Survey.JsonObject.metaData.addClass("signaturepad", [], null, "empty");
      Survey.JsonObject.metaData.addClass('quilleditor', [], null, 'empty')
    },
    //If you want to use the default question rendering then set this property to true. We do not need any default rendering, we will use our our htmlTemplate
    isDefaultRender: false,
    //You should use it if your set the isDefaultRender to false
    // htmlTemplate: "<div><textarea rows='10' cols='80' style: {width:'100%'}></textarea></div>",
    htmlTemplate: "<div id='editor-container'></div>",
    //The main function, rendering and two-way binding
    afterRender: function (question, el) {
      var name = question.inputId
      el.name = name

      var editor = new Quill(el, {
        theme: 'snow',
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'], // toggled buttons
            ['blockquote', 'code-block'],
            ['link'],
            [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
            [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
            [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
            [{ direction: 'rtl' }], // text direction
            [{ header: [1, 2, 3, 4, 5, 6, false] }],

            [{ color: [] }, { background: [] }], // dropdown with defaults from theme
            [{ font: [] }],
            [
              {
                size: ['10px', '11px', '12px', '14px', '18px', '22px', '24px'],
              },
            ],
            [{ align: [] }],
            ['clean'], // remove formatting button
          ],
        },
      })

      var isValueChanging = false
      var updateValueHandler = function () {
        if (isValueChanging || typeof question.value === 'undefined') return
        editor.pasteHTML(question.value)
      }
      editor.on('text-change', function () {
        isValueChanging = true
        question.value = editor?.root?.innerHTML
        // question.value = editor?.getContents();
        isValueChanging = false
      })

      question.valueChangedCallback = updateValueHandler
      question.readOnlyChangedCallback = function () {
        if (question.isReadOnly) {
          editor.setReadOnly(true)
        } else {
          editor.setReadOnly(false)
        }
      }
      updateValueHandler()
    },
    //Use it to destroy the widget. It is typically needed by jQuery widgets
    willUnmount: function (question, el) {
      //We do not need to clear anything in our simple example
      //Here is the example to destroy the image picker
      //var $el = $(el).find("select");
      //$el.data('picker').destroy();
    },
  }

  //Register our widget in singleton custom widget collection
  Survey.CustomWidgetCollection.Instance.addCustomWidget(widget, 'customtype')
}

export default QuillEditortcustomwidget
