import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import withStyles from '@material-ui/core/styles/withStyles'

import Logo from '../../logo.svg'
import User from './User'
import routes from '../../utilities/routes'

const styles = (theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: '#8c1f27',
    color: '#ffffff',
  },
  appBar1: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: '#2839b5',
    color: '#ffffff',
  },
  logo: {
    height: 40,
    marginRight: '0.5em',
    width: 35,
    color: '#ffffff',
  },
  menu: {
    color: '#ffffff',
    fontSize: '2.5rem',
  },
  spacer: {
    flexGrow: 1,
  },
  appName: {
    fontSize: '18px',
    fontWeight: '400',
  },
})

const Header = (props) => {
  const { classes, menuAction, location, appId } = props
  return (
    <AppBar color="default" className={classes.appBar}>
      <Toolbar>
        {location?.pathname === '/' || appId === 0 ? (
          ''
        ) : (
          <IconButton onClick={menuAction} aria-label="Menu">
            <MenuIcon className={classes.menu} />
          </IconButton>
        )}

        <Link to={routes.app.index} aria-label="Go to home">
          <Logo className={classes.logo} />
        </Link>
        <div className={classes.appName}>Demeter</div>
        <span className={classes.spacer} />
        <User />
      </Toolbar>
    </AppBar>
  )
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Header)
