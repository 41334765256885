import $ from 'jquery'

import * as SurveyCore from 'survey-core'
import * as widgets from 'surveyjs-widgets'
import * as SurveyPDF from 'survey-pdf'

widgets.prettycheckbox(SurveyCore)
widgets.select2(SurveyCore, $)
widgets.inputmask(SurveyCore)
widgets.jquerybarrating(SurveyCore, $)
widgets.jqueryuidatepicker(SurveyCore, $)
widgets.nouislider(SurveyCore)
widgets.select2tagbox(SurveyCore, $)
widgets.sortablejs(SurveyCore)
widgets.ckeditor(SurveyCore)
widgets.autocomplete(SurveyCore, $)
widgets.bootstrapslider(SurveyCore)

function savePDF(model) {
  var surveyPDF = new SurveyPDF.SurveyPDF(model)
  surveyPDF.save()
}

export function ExportToPDFPage(props) {
  const { model } = props
  return <button onClick={() => savePDF(model)}>Save PDF</button>
}
