import { Helmet } from 'react-helmet'

import withStyles from '@material-ui/core/styles/withStyles'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

const styles = (theme) => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    width: '50vw',
    margin: '2em auto',
  },
})

const AccessDeniedPage = ({ classes }) => {
  return (
    <Paper
      className={classes.root}
      elevation={1}
      data-testid="AccessDenied"
      align="center"
    >
      <Helmet title="Access Denied" />
      <Typography variant="h3">
        You are currently not authorized to use this site.
      </Typography>
      <div>
        <Typography variant="h4" align="center">
          Please reach out to admin team to get access.
        </Typography>
        <Typography variant="h4" align="center">
          # demeter-admins
        </Typography>
      </div>
    </Paper>
  )
}

export default withStyles(styles)(AccessDeniedPage)
