import React, { useState, useEffect, useContext } from 'react'
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigate,
} from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { useAuth, ProtectedElement } from '@praxis/component-auth'
import HomePage from '../HomePage'
import { CreatorPage } from '../surveyCreator/Creator'
import { SurveyAnswer } from '../surveyAnswer/SurveyAnswer'
import { ExportToPDFPage } from '../surveyReport/Export'
import AnalyticsPage from '../surveyReport/AnalyticsPage'
import { AnalyticsTabulatorPage } from '../surveyReport/AnalyticsTabulator'
import routes from '../../utilities/routes'
import AppHeader from './Header'
import ToolbarSpacer from '../../shared/ToolbarSpacer'
import AccessDeniedPage from '../../shared/AccessDeniedPage'
import CreateDeployment from '../deployment/CreateDeployment'
import DeploymentPage from '../deployment/DeploymentPage'
import CreateGroup from '../group/CreateGroup'
import GroupComponent from '../group/GroupComponent'
import HomeIcon from '@material-ui/icons/Home'
import ViewListIcon from '@material-ui/icons/ViewList'
import CollectionsIcon from '@material-ui/icons/Collections'
import FilterListIcon from '@material-ui/icons/FilterList'
import withStyles from '@material-ui/core/styles/withStyles'
import Survey from '../surveyAnswer/Survey'
import { useEnv } from '@praxis/component-runtime-env'
import { Interceptor } from './Interceptor'
import { CURRENT_URL, ROLE } from '../../utilities/constants'
import SurveyAnswersReport from '../surveyAnswer/SurveyAnswersReport'
import LandingPage from '../../shared/LandingPage'
import { ApplicationContext } from '../contexts/ApplicationContext'
import DisplayImage from '../surveyReport/Images'
import Drawer from '@material-ui/core/Drawer'

import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { getAllRoleIdentities } from '../../utilities/restService'

const styles = () => ({
  list: {
    width: 250,
  },
  iconFontSize: {
    fontSize: '2.5rem',
  },
  textFontSize: {
    fontSize: '14px',
  },
})
const Layout = (props) => {
  const { classes } = props
  const { appId, setRoleIdentities, roleIdentities } =
    useContext(ApplicationContext)
  const [sideNavIsOpen, setSideNavIsOpen] = useState(false)
  // const [roleIdentities, setRoleIdentities] = useState([])
  let navigate = useNavigate()

  const auth = useAuth() // connect to the \`AuthContext\` provided by \`App\` below, using a React hook.
  const { isAuthorized, logout, session } = auth // assign variables of same name as properties off of \`auth\`
  const env = useEnv()

  console.log(session)

  useEffect(() => {
    if (roleIdentities.length === 0) {
      getAllRoleIdentities()
        .then((res) => {
          if (res.status === 200) {
            var result = res?.data.filter((r) =>
              session?.userInfo?.memberOf.includes(
                r.ad_group_name?.toUpperCase(),
              ),
            )
            setRoleIdentities(result)
          }
        })
        .catch((error) => {})
    }
  }, [session?.userInfo?.memberOf, roleIdentities.length, setRoleIdentities])

  const handleCloseSideNav = () => {
    if (sideNavIsOpen) {
      setSideNavIsOpen(false)
    }
  }
  const handleMenuButtonClick = () => {
    if (sideNavIsOpen) {
      setSideNavIsOpen(false)
    } else {
      setSideNavIsOpen(true)
    }
  }

  const RequiredAuth = ({ child }) => {
    let location = useLocation()

    if (session?.isAuthenticated) {
      if (CURRENT_URL?.ACCESS) {
        return child
      } else if (!location?.pathname?.includes('survey')) {
        return <Navigate to={routes.app.surveys} replace />
      } else {
        return child
      }
    } else {
      return <Navigate to="/accessDenied" replace />
    }
  }

  const naviGateToRoute = (url) => {
    handleMenuButtonClick()
    navigate(url)
  }

  var filterRoles = roleIdentities
    ?.filter((r) => r.application_id.toString() === appId.toString())
    .map((r) => r.role)

  return (
    <React.Fragment>
      <>
        {isAuthorized() ? (
          <>
            <Interceptor
              session={session}
              env={env}
              logout={logout}
              appId={appId}
            />
            <Helmet defaultTitle="Demeter" titleTemplate="%s - Demeter" />

            <AppHeader appId={appId} menuAction={handleMenuButtonClick} />
            <ToolbarSpacer />
            <Drawer
              anchor={'left'}
              open={sideNavIsOpen}
              onClose={handleCloseSideNav}
            >
              <List>
                {appId !== 0 && CURRENT_URL.ACCESS ? (
                  <ListItem
                    button
                    onClick={() =>
                      naviGateToRoute('/application/' + appId + '/assessments')
                    }
                  >
                    <ListItemIcon>
                      <HomeIcon className={classes.iconFontSize} />
                    </ListItemIcon>
                    <ListItemText>
                      <div className={classes.textFontSize}>
                        Field Assessments
                      </div>
                    </ListItemText>
                  </ListItem>
                ) : (
                  ''
                )}

                {appId !== 0 && CURRENT_URL.ACCESS ? (
                  <ListItem
                    button
                    onClick={() =>
                      naviGateToRoute('/application/' + appId + '/deployments')
                    }
                  >
                    <ListItemIcon>
                      <CollectionsIcon className={classes.iconFontSize} />
                    </ListItemIcon>
                    <ListItemText>
                      <div className={classes.textFontSize}>Deployments</div>
                    </ListItemText>
                  </ListItem>
                ) : (
                  ''
                )}
                {appId !== 0 &&
                CURRENT_URL.ACCESS &&
                filterRoles?.includes(ROLE.ADMIN) ? (
                  <ListItem
                    button
                    onClick={() => naviGateToRoute(routes.app.groups)}
                  >
                    <ListItemIcon>
                      <ViewListIcon className={classes.iconFontSize} />
                    </ListItemIcon>
                    <ListItemText>
                      <div className={classes.textFontSize}>Groups</div>
                    </ListItemText>
                  </ListItem>
                ) : (
                  ''
                )}
                <ListItem
                  button
                  onClick={() => naviGateToRoute(routes.app.surveys)}
                >
                  <ListItemIcon>
                    <FilterListIcon className={classes.iconFontSize} />
                  </ListItemIcon>
                  <ListItemText>
                    <div className={classes.textFontSize}>My Assessments</div>
                  </ListItemText>
                </ListItem>
              </List>
              <Divider />
            </Drawer>
          </>
        ) : (
          ''
        )}
      </>

      <Routes>
        <>
          <Route
            exact
            path={routes.app.index}
            element={
              <ProtectedElement>
                <RequiredAuth child={<LandingPage />} />
              </ProtectedElement>
            }
          ></Route>

          <Route
            exact
            path={routes.app.assessments}
            element={
              <ProtectedElement>
                <RequiredAuth child={<HomePage />} />
              </ProtectedElement>
            }
          ></Route>

          <Route
            path={routes.app.surveyAnswerUpdateUrl}
            element={
              <ProtectedElement>
                <RequiredAuth child={<SurveyAnswer />} />
              </ProtectedElement>
            }
          ></Route>

          <Route
            path={routes.app.surveyAnswerViewUrl}
            element={
              <ProtectedElement>
                <RequiredAuth child={<SurveyAnswer />} />
              </ProtectedElement>
            }
          ></Route>

          <Route
            path={routes.app.image}
            element={
              <ProtectedElement>
                <RequiredAuth child={<DisplayImage />} />
              </ProtectedElement>
            }
          ></Route>

          <Route
            path={routes.app.surveyAnswerUrl}
            element={
              <ProtectedElement>
                <RequiredAuth child={<SurveyAnswer />} />
              </ProtectedElement>
            }
          ></Route>

          <Route
            path={routes.app.surveys}
            element={
              <ProtectedElement>
                <RequiredAuth child={<Survey />} />
              </ProtectedElement>
            }
          ></Route>

          <Route
            path={routes.app.surveyAnswers}
            element={
              <ProtectedElement>
                <RequiredAuth child={<SurveyAnswersReport />} />
              </ProtectedElement>
            }
          ></Route>

          <Route
            path={routes.app.creator}
            element={
              <ProtectedElement>
                <RequiredAuth child={<CreatorPage />} />
              </ProtectedElement>
            }
          ></Route>

          <Route
            path={routes.app.update}
            element={
              <ProtectedElement>
                <RequiredAuth child={<CreatorPage />} />
              </ProtectedElement>
            }
          ></Route>

          <Route
            path={routes.app.export}
            element={
              <ProtectedElement>
                <RequiredAuth child={<ExportToPDFPage />} />
              </ProtectedElement>
            }
          ></Route>

          <Route
            path={routes.app.analytics}
            element={
              <ProtectedElement>
                <RequiredAuth child={<AnalyticsPage />} />
              </ProtectedElement>
            }
          ></Route>

          <Route
            path={routes.app.deploymentCreatorByFormId}
            element={
              <ProtectedElement>
                <RequiredAuth child={<CreateDeployment />} />
              </ProtectedElement>
            }
          ></Route>

          <Route
            path={routes.app.deploymentCreator}
            element={
              <ProtectedElement>
                <RequiredAuth child={<CreateDeployment />} />
              </ProtectedElement>
            }
          ></Route>

          <Route
            path={routes.app.deploymentUpdate}
            element={
              <ProtectedElement>
                <RequiredAuth child={<CreateDeployment />} />
              </ProtectedElement>
            }
          ></Route>

          <Route
            path={routes.app.deployments}
            element={
              <ProtectedElement>
                <RequiredAuth child={<DeploymentPage />} />
              </ProtectedElement>
            }
          ></Route>

          <Route
            exact
            path={routes.app.groupCreator}
            element={
              <ProtectedElement>
                <RequiredAuth child={<CreateGroup />} />
              </ProtectedElement>
            }
          ></Route>

          <Route
            exact
            path={routes.app.groupUpdate}
            element={
              <ProtectedElement>
                <RequiredAuth child={<CreateGroup />} />
              </ProtectedElement>
            }
          ></Route>

          <Route
            path={routes.app.analyticstabulator}
            element={
              <ProtectedElement>
                <RequiredAuth child={<AnalyticsTabulatorPage />} />
              </ProtectedElement>
            }
          ></Route>

          <Route
            exact
            path={routes.app.groups}
            element={
              <ProtectedElement>
                <RequiredAuth child={<GroupComponent />} />
              </ProtectedElement>
            }
          ></Route>

          <Route
            path="*"
            element={
              <ProtectedElement>
                <AccessDeniedPage />
              </ProtectedElement>
            }
          />
        </>
      </Routes>
    </React.Fragment>
  )
}

export default withStyles(styles)(Layout)
