import 'date-fns'
import React, { useState, useEffect, useContext } from 'react'
import { Helmet } from 'react-helmet'
import { useNavigate, useParams } from 'react-router-dom'
import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import TextField from '@material-ui/core/TextField'
import Checkbox from '@material-ui/core/Checkbox'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import MenuItem from '@material-ui/core/MenuItem'
import withStyles from '@material-ui/core/styles/withStyles'
import { Grid, Typography, FormControl } from '@material-ui/core'
import {
  getAllSurveys,
  saveDeployment,
  updateDeployment,
  getDeploymentById,
  getAllCustomGroups,
  getRecipientsByEmailId,
  updateDeploymentStatus,
  getBusinessPartners,
  applyFormChangesToDeployment,
} from '../../utilities/restService'
import { formatDate } from '../../utilities/date'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import muiTheme from '../../config/themeConfig'
import LoadingButton from '../../shared/LoadingButton'
import CustomizedSnackbars from '../../shared/CustomizedSnackbars'
import RecipientTable from '../group/RecipientTable'
import {
  ASSESSMENT_OWNER,
  DEPLOYMENT_MODE_OPTIONS,
  DEPLOYMENT_AUDIENCE_OPTIONS,
  DEPLOYMENT_MODE_VALUES,
  DEPLOYMENT_TYPE_OPTIONS,
  DEPLOYMENT_TYPE_VALUES,
  STATUS,
  DEPLOYMENT_AUDIENCE_VALUES,
  LOCKING_DURATION_VALUES,
  LOCKING_DURATION_FILTERS,
  LOCKING_DURATION_FILTER_OPTIONS,
} from '../../utilities/constants'
import { confirmAlert } from 'react-confirm-alert' // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import ReactQuill from 'react-quill'
import QuillToolbar, { modules, formats } from './QuillToolbar'
import 'react-quill/dist/quill.snow.css'
import { useEnv } from '@praxis/component-runtime-env'
import CircularLoading from '../../shared/CircularLoading'
import { ApplicationContext } from '../contexts/ApplicationContext'
import BusinessPartnerTable from './BusinessPartnerTable'
import AddedBusinessPartnerTable from './AddedBusinessPartnerTable'

const styles = (theme) => ({
  formControl: {
    margin: theme.spacing(1),

    maxWidth: 330,
    minWidth: 330,
    marginLeft: 13,
    marginBottom: 20,
  },
  dateFormControl: {
    margin: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
    paddingTop: '19px',
  },
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
  },
})

const CreateDeployment = (props) => {
  const { classes } = props
  const env = useEnv()
  const [dueDate, setDueDate] = useState(null)
  const [deploymentId, setDeploymentId] = useState('')
  const [deploymentName, setDeploymentName] = useState('')
  const [fieldAssessmentId, setFieldAssessmentId] = useState('')
  const [deploymentType, setDeploymentType] = useState('')
  const [deploymentMode, setDeploymentMode] = useState(
    DEPLOYMENT_MODE_VALUES.INTERNAL,
  )
  const [deploymentAudience, setDeploymentAudience] = useState(
    DEPLOYMENT_AUDIENCE_VALUES.OPEN,
  )
  const [lockingDurationFilter, setLockingDurationFilter] = useState(
    LOCKING_DURATION_FILTER_OPTIONS.THREE_DAYS,
  )
  const [lockingDuration, setLockingDuration] = useState(
    LOCKING_DURATION_VALUES.THREE_DAYS,
  )
  const [isLoading, setIsLoading] = useState(false)
  const [isCircularLoading, setIsCircularLoading] = useState(false)
  const [fieldAssessmentData, setFieldAssessmentData] = useState([])
  const [message, setMessage] = useState('')
  const [isSuccess, setIsSuccess] = useState(false)
  const [groupData, setGroupData] = useState([])
  const [groupId, setGroupId] = useState(0)
  const [teamMemberList, setTeamMemberList] = useState([])
  const [bpList, setBPList] = useState([])
  const [teamMember, setTeamMember] = useState('')
  const [bccEmails, setBccEmails] = useState('')
  const [emailBody, setEmailBody] = useState(
    '<p>Hi,</p>' +
      '<p>Below assessment has been assigned to you for completion.</p>' +
      '<p><span style="text-decoration: underline; color: #3598db;">{survey_link}</span></p>',
  )
  const [isTMLoading, setIsTMLoading] = useState(false)
  const [isReadOnly, setIsReadOnly] = useState(false)
  const [isSendEmailChecked, setIsSendEmailChecked] = useState(true)
  const [isOpenDeploymentChecked, setIsOpenDeploymentChecked] = useState(true)
  const [isArcherPullRequest, setIsArcherPullRequest] = useState(false)
  const { setAppId } = useContext(ApplicationContext)

  let navigate = useNavigate()

  let { formId, appId, deployId } = useParams()

  if (formId !== undefined && fieldAssessmentId === '') {
    setFieldAssessmentId(formId)
  }

  const addSelectedBP = (selectedRows) => {
    var selectedBPs = selectedRows.data.map(function (obj) {
      var bpdata = bpList[obj.dataIndex]
      if (bpdata.bp_id !== undefined) {
        var filteredTeamMemberData = teamMemberList.filter(
          (t) => t.bp_id === bpdata.bp_id,
        )
        if (filteredTeamMemberData.length === 0) {
          return bpdata
        }
      }
      return ''
    })
    var filtered = selectedBPs.filter((x) => x !== '')
    setTeamMemberList([...teamMemberList, ...filtered])
  }

  const addBP = (bp_id) => {
    setIsSuccess(true)
    setMessage('Recipient added successfully')
    try {
      var bpdata = bpList.filter((t) => t.id === bp_id)
      if (bpdata[0].bp_id !== undefined) {
        var filteredTeamMemberData = teamMemberList.filter(
          (t) => t.bp_id === bpdata[0].bp_id,
        )
        if (filteredTeamMemberData.length === 0) {
          setTeamMemberList([...teamMemberList, bpdata[0]])
        }
      } else {
        setIsSuccess(false)
        setMessage('Please enter valid id')
      }
    } catch (e) {
      if (e.response && e.response.data) {
        setIsSuccess(false)
        setMessage(e?.response?.data?.message)
      }
    }
  }

  function getFieldAssessmentData() {
    setIsLoading(true)
    getAllSurveys(ASSESSMENT_OWNER.ALL)
      .then((res) => {
        setFieldAssessmentData(res.data)
        setIsLoading(false)
      })
      .catch((error) => {
        setIsLoading(false)
      })
  }

  function getAllCustomGroupData() {
    setIsLoading(true)
    getAllCustomGroups()
      .then((res) => {
        setGroupData(res.data)
        setIsLoading(false)
      })
      .catch((error) => {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    if (appId !== undefined) {
      setAppId(appId)
    }

    getFieldAssessmentData()
    getAllCustomGroupData()
  }, [appId, setAppId])

  useEffect(() => {
    if (appId !== undefined && deploymentId === '') {
      if (deployId !== undefined) {
        setDeploymentId(deployId)
        setIsLoading(true)
        getDeploymentById(deployId)
          .then((res) => {
            if (res.data.name !== undefined) {
              setDeploymentName(res.data.name)
              setDueDate(
                res.data.due_date !== null ? new Date(res.data.due_date) : null,
              )
              setFieldAssessmentId(res.data.survey_id)
              setGroupId(res.data.custom_group_id)
              setDeploymentType(res.data.deployment_type)
              setIsOpenDeploymentChecked(res.data.is_open_deployment)
              if (res.data.is_open_deployment === true) {
                setDeploymentAudience(DEPLOYMENT_AUDIENCE_VALUES.OPEN)
              } else if (res.data.is_open_deployment === false) {
                setDeploymentAudience(DEPLOYMENT_AUDIENCE_VALUES.ASSIGNED)
              }
              setIsArcherPullRequest(res.data.is_archer_pull_request)
              setLockingDuration(res.data.assessment_locking_duration)
              if (
                res.data.assessment_locking_duration ===
                LOCKING_DURATION_VALUES.NOW
              ) {
                setLockingDurationFilter(LOCKING_DURATION_FILTER_OPTIONS.NOW)
              } else if (
                res.data.assessment_locking_duration ===
                LOCKING_DURATION_VALUES.ONE_DAY
              ) {
                setLockingDurationFilter(
                  LOCKING_DURATION_FILTER_OPTIONS.ONE_DAY,
                )
              } else if (
                res.data.assessment_locking_duration ===
                LOCKING_DURATION_VALUES.THREE_DAYS
              ) {
                setLockingDurationFilter(
                  LOCKING_DURATION_FILTER_OPTIONS.THREE_DAYS,
                )
              } else if (
                res.data.assessment_locking_duration ===
                LOCKING_DURATION_VALUES.NEVER
              ) {
                setLockingDurationFilter(LOCKING_DURATION_FILTER_OPTIONS.NEVER)
              }

              setDeploymentMode(res.data.deployment_mode)(
                res.data.deployment_mode === DEPLOYMENT_MODE_VALUES.INTERNAL
                  ? setTeamMemberList(res.data.user_list)
                  : (setIsCircularLoading(true),
                    getBusinessPartners()
                      .then((res) => {
                        if (res.data !== undefined) {
                          setBPList(res.data)
                        }
                        setIsCircularLoading(false)
                      })
                      .catch((error) => {
                        setIsCircularLoading(false)
                      }),
                    setTeamMemberList(res.data.business_partners_list)),
              )
              if (
                res.data.body_email !== undefined &&
                res.data.body_email !== null &&
                res.data.body_email.trim() !== ''
              ) {
                setEmailBody(res.data.body_email)
              }
              setBccEmails(res.data.bcc_emails)
              setIsSendEmailChecked(res.data.is_send_email)
              if (res.data.status === STATUS.COMPLETED) {
                setIsReadOnly(true)
              }
            }
            setIsLoading(false)
          })
          .catch((error) => {
            setIsLoading(false)
          })
      }
    }
  }, [appId, deploymentId, deployId])

  const handleTeamMemberAdd = () => {
    if (deploymentMode !== DEPLOYMENT_MODE_VALUES.VENDOR_FACING) {
      setIsTMLoading(true)

      getRecipientsByEmailId(teamMember)
        .then((res) => {
          if (res.data.email_id !== undefined) {
            var filteredTeamMemberData = teamMemberList.filter(
              (t) => t.email_id === res.data.email_id,
            )
            if (filteredTeamMemberData.length === 0) {
              setTeamMemberList([...teamMemberList, res.data])
            }
          }
          setTeamMember('')
          setIsTMLoading(false)
        })
        .catch((error) => {
          setIsTMLoading(false)
        })
    }
  }

  const deleteRecipient = (recipientId) => {
    setTeamMemberList(teamMemberList.filter((t) => t.id !== recipientId))
  }

  function handleEmailBodyChange(value) {
    setEmailBody(value)
  }

  function getVendorDeploymentSurveys() {
    var vendorDeploymentSurveys = []
    for (var i = 0; i < teamMemberList.length; i++) {
      var objGroup = {
        user_type: 'Group',
        vendor_custom_group_id: teamMemberList[i].id,
        is_send_email_notification: isSendEmailChecked,
      }
      vendorDeploymentSurveys = [...vendorDeploymentSurveys, objGroup]
    }
    return vendorDeploymentSurveys
  }

  function getUserDeploymentSurveys() {
    var userDeploymentSurveys = []
    for (var i = 0; i < teamMemberList.length; i++) {
      var objUser = {
        user_type: 'User',
        user_custom_group_id: teamMemberList[i].id,
        is_send_email_notification: isSendEmailChecked,
      }
      userDeploymentSurveys = [...userDeploymentSurveys, objUser]
    }
    if (groupId !== 0 && groupId !== '' && groupId !== undefined) {
      var objGroup = {
        user_type: 'Group',
        user_custom_group_id: groupId,
        is_send_email_notification: isSendEmailChecked,
      }
      userDeploymentSurveys = [...userDeploymentSurveys, objGroup]
    }

    return userDeploymentSurveys
  }
  function createDeployment() {
    var request
    if (deploymentMode === DEPLOYMENT_MODE_VALUES.INTERNAL) {
      var userDeploymentSurveys = getUserDeploymentSurveys()
      request = {
        bcc_emails: bccEmails,
        body_email: emailBody,
        is_send_email: isSendEmailChecked,
        name: deploymentName,
        survey_id: fieldAssessmentId,
        due_date:
          dueDate !== null && deploymentType === DEPLOYMENT_TYPE_VALUES.ONETIME
            ? formatDate(dueDate)
            : null,
        user_deployment_surveys: userDeploymentSurveys,
        deployment_type: deploymentType,
        is_open_deployment: isOpenDeploymentChecked,
        is_archer_pull_request: isArcherPullRequest,
        deployment_mode: deploymentMode,
        assessment_locking_duration: lockingDuration,
      }
      saveDeployment(request)
        .then((res) => {
          setMessage('Deployment created successfully.')
          setIsLoading(false)
          setIsSuccess(true)
          navigate('/application/' + appId + '/deploymentUpdate/' + res.data.id)
        })
        .catch((error) => {
          setIsLoading(false)
        })
    } else {
      var vendorDeploymentSurveys = getVendorDeploymentSurveys()
      request = {
        bcc_emails: bccEmails,
        body_email: emailBody,
        is_send_email: isSendEmailChecked,
        name: deploymentName,
        survey_id: fieldAssessmentId,
        due_date:
          dueDate !== null && deploymentType === DEPLOYMENT_TYPE_VALUES.ONETIME
            ? formatDate(dueDate)
            : null,
        vendor_deployment_surveys: vendorDeploymentSurveys,
        deployment_type: deploymentType,
        is_open_deployment: isOpenDeploymentChecked,
        is_archer_pull_request: isArcherPullRequest,
        deployment_mode: deploymentMode,
        assessment_locking_duration: lockingDuration,
      }
      saveDeployment(request)
        .then((res) => {
          setMessage('Deployment created successfully.')
          setIsLoading(false)
          setIsSuccess(true)
          navigate('/application/' + appId + '/deploymentUpdate/' + res.data.id)
        })
        .catch((error) => {
          setIsLoading(false)
        })
    }
  }

  function modifyDeployment(id) {
    var request
    if (deploymentMode === DEPLOYMENT_MODE_VALUES.INTERNAL) {
      var userDeploymentSurveys = getUserDeploymentSurveys()

      request = {
        bcc_emails: bccEmails,
        body_email: emailBody,
        is_send_email: isSendEmailChecked,
        name: deploymentName,
        survey_id: fieldAssessmentId,
        due_date:
          dueDate !== null && deploymentType === DEPLOYMENT_TYPE_VALUES.ONETIME
            ? formatDate(dueDate)
            : null,
        user_deployment_surveys: userDeploymentSurveys,
        deployment_type: deploymentType,
        deployment_mode: deploymentMode,
        is_open_deployment: isOpenDeploymentChecked,
        is_archer_pull_request: isArcherPullRequest,
        assessment_locking_duration: lockingDuration,
      }
      updateDeployment(request, id)
        .then((res) => {
          setMessage('Deployment updated successfully.')
          setIsLoading(false)
          setIsSuccess(true)
          setBccEmails('')
        })
        .catch((error) => {
          setIsLoading(false)
        })
    } else {
      var vendorDeploymentSurveys = getVendorDeploymentSurveys()

      request = {
        bcc_emails: bccEmails,
        body_email: emailBody,
        is_send_email: isSendEmailChecked,
        name: deploymentName,
        survey_id: fieldAssessmentId,
        due_date:
          dueDate !== null && deploymentType === DEPLOYMENT_TYPE_VALUES.ONETIME
            ? formatDate(dueDate)
            : null,
        vendor_deployment_surveys: vendorDeploymentSurveys,
        deployment_type: deploymentType,
        deployment_mode: deploymentMode,
        is_open_deployment: isOpenDeploymentChecked,
        is_archer_pull_request: isArcherPullRequest,
        assessment_locking_duration: lockingDuration,
      }
      updateDeployment(request, id)
        .then((res) => {
          setMessage('Deployment updated successfully.')
          setIsLoading(false)
          setIsSuccess(true)
          setBccEmails('')
        })
        .catch((error) => {
          setIsLoading(false)
        })
    }
  }

  const handleDeploymentMode = (event) => {
    const prev_mode = deploymentMode
    setDeploymentMode(event.target.value)
    if (prev_mode !== DEPLOYMENT_MODE_VALUES.VENDOR_FACING) {
      setIsArcherPullRequest(true)
      setDeploymentType(DEPLOYMENT_TYPE_VALUES.PERSISTENT)
      setIsSendEmailChecked(false)
      setLockingDuration(LOCKING_DURATION_VALUES.THREE_DAYS)
      setLockingDurationFilter(LOCKING_DURATION_FILTER_OPTIONS.THREE_DAYS)
      setIsOpenDeploymentChecked(false)
      setDeploymentAudience(DEPLOYMENT_AUDIENCE_VALUES.ASSIGNED)

      if (isArcherPullRequest === false) {
        setIsCircularLoading(true)
        getBusinessPartners()
          .then((res) => {
            if (res.data !== undefined) {
              setBPList(res.data)
            }
            setIsCircularLoading(false)
          })
          .catch((error) => {
            setIsCircularLoading(false)
          })
      }
    } else {
      setIsArcherPullRequest(false)
      setDeploymentType('')
    }
    if (event.target.value !== prev_mode) {
      setGroupId(0)
      teamMemberList.length = 0
      if (event.target.value === DEPLOYMENT_MODE_VALUES.INTERNAL) {
        setIsSendEmailChecked(true)
      }
    }
  }

  const handleSendEmailCheckbox = (event) => {
    setIsSendEmailChecked(event.target.checked)
  }
  const handleDeploymentAudience = (event) => {
    setDeploymentAudience(event.target.value)
    if (event.target.value === DEPLOYMENT_AUDIENCE_VALUES.OPEN) {
      setIsOpenDeploymentChecked(true)
      setIsSendEmailChecked(false)
    } else if (event.target.value === DEPLOYMENT_AUDIENCE_VALUES.ASSIGNED) {
      setIsOpenDeploymentChecked(false)
      setIsSendEmailChecked(true)
    }
  }

  const handleLockingDuration = (event) => {
    setLockingDurationFilter(event.target.value)
    if (event.target.value === LOCKING_DURATION_FILTER_OPTIONS.NOW) {
      setLockingDuration(LOCKING_DURATION_VALUES.NOW)
    } else if (event.target.value === LOCKING_DURATION_FILTER_OPTIONS.ONE_DAY) {
      setLockingDuration(LOCKING_DURATION_VALUES.ONE_DAY)
    } else if (
      event.target.value === LOCKING_DURATION_FILTER_OPTIONS.THREE_DAYS
    ) {
      setLockingDuration(LOCKING_DURATION_VALUES.THREE_DAYS)
    } else if (event.target.value === LOCKING_DURATION_FILTER_OPTIONS.NEVER) {
      setLockingDuration(LOCKING_DURATION_VALUES.NEVER)
    }
  }

  const handleIsArcherPullRequest = (event) => {
    setDeploymentType(DEPLOYMENT_TYPE_VALUES.PERSISTENT)
    setIsArcherPullRequest(event.target.checked)
    setGroupId(0)
    teamMemberList.length = 0
  }
  const handleDeploymentLaunch = () => {
    setIsLoading(true)
    setIsSuccess(false)
    if (deploymentId === '') {
      createDeployment()
    } else {
      modifyDeployment(deploymentId)
    }
  }

  const handleFormChangesToDeployment = () => {
    setIsLoading(true)
    setIsSuccess(false)
    let request = {
      survey_id: fieldAssessmentId,
    }
    applyFormChangesToDeployment(request, deploymentId)
      .then((res) => {
        setMessage('Latest form changes applied to existing Deployment.')
        setIsSuccess(true)
        setIsLoading(false)
      })
      .catch((error) => {
        setIsLoading(false)
      })
  }
  const handleDeploymentComplete = () => {
    confirmAlert({
      title: 'Deployment',
      message:
        'Are you sure you want complete this deployment? This will lock all open assessments.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            setIsLoading(true)
            setIsSuccess(false)
            updateDeploymentStatus(deploymentId, STATUS.COMPLETED)
              .then((res) => {
                setMessage('Deployment status updated successfully.')
                setIsLoading(false)
                setIsSuccess(true)
                setIsReadOnly(true)
              })
              .catch((error) => {
                setIsLoading(false)
              })
          },
        },
        {
          label: 'No',
          onClick: () => {
            return false
          },
        },
      ],
    })
  }

  var isDisabled = false
  if (
    deploymentMode === DEPLOYMENT_MODE_VALUES.INTERNAL &&
    (isLoading ||
      deploymentType === '' ||
      deploymentName.trim() === '' ||
      fieldAssessmentId === '' ||
      (teamMemberList?.length === 0 && groupId === 0))
  ) {
    isDisabled = true
  }
  if (
    deploymentMode === DEPLOYMENT_MODE_VALUES.VENDOR_FACING &&
    (isLoading ||
      deploymentType === '' ||
      deploymentName.trim() === '' ||
      fieldAssessmentId === '' ||
      (isArcherPullRequest === false && teamMemberList?.length === 0))
  ) {
    isDisabled = true
  }
  if (isSendEmailChecked && emailBody === '') {
    isDisabled = true
  }
  if (isOpenDeploymentChecked) {
    if (
      deploymentName === '' ||
      deploymentMode === '' ||
      deploymentType === '' ||
      fieldAssessmentId === ''
    ) {
      isDisabled = true
    } else {
      isDisabled = false
    }
  }

  return (
    <React.Fragment>
      <Helmet title="Create Deployment" />
      {isSuccess ? (
        <CustomizedSnackbars variant="success" message={message} />
      ) : (
        ''
      )}
      {isLoading && deploymentId !== '' ? (
        <CircularLoading />
      ) : (
        <MuiThemeProvider theme={muiTheme}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div className={classes.paper}>
              <Typography variant="h4">Deployment Details</Typography>
              <Grid container>
                {deploymentId === '' ? (
                  ''
                ) : (
                  <Grid item>
                    <FormControl className={classes.formControl}>
                      <TextField
                        id="deploymentId"
                        label="Deployment Id"
                        name="deploymentId"
                        disabled
                        value={deploymentId}
                      />
                    </FormControl>
                  </Grid>
                )}
                <Grid item>
                  <FormControl className={classes.formControl}>
                    <TextField
                      id="deploymentName"
                      label="Deployment Name"
                      name="deploymentName"
                      required
                      value={deploymentName}
                      onChange={(e) => setDeploymentName(e.target.value)}
                    />
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl required className={classes.formControl}>
                    <InputLabel id="fieldAssessmentId-select-label">
                      Field Assessment
                    </InputLabel>
                    <Select
                      labelId="fieldAssessmentId-select-label"
                      id="fieldAssessmentId-select"
                      name="fieldAssessmentId"
                      value={fieldAssessmentId}
                      disabled={deploymentId === '' ? false : true}
                      onChange={(e) => setFieldAssessmentId(e.target.value)}
                    >
                      {fieldAssessmentData.map((fa) => {
                        return (
                          <MenuItem key={fa.id} value={fa.id}>
                            {fa.name}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item>
                  <FormControl required className={classes.formControl}>
                    <InputLabel id="deploymentType-select-label">
                      Deployment Type
                    </InputLabel>
                    <Select
                      labelId="deploymentType-select-label"
                      id="deploymentType-select"
                      name="deploymentType"
                      value={deploymentType}
                      disabled={deploymentId === '' ? false : true}
                      onChange={(e) => setDeploymentType(e.target.value)}
                    >
                      {DEPLOYMENT_TYPE_OPTIONS.map((d, index) => {
                        return (
                          <MenuItem key={index} value={d}>
                            {d}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl required className={classes.formControl}>
                    <InputLabel id="deploymentMode-select-label">
                      Deployment Mode
                    </InputLabel>
                    <Select
                      labelId="deploymentMode-select-label"
                      id="deploymentMode-select"
                      name="deploymentMode"
                      value={deploymentMode}
                      disabled={
                        (deploymentId === '' ? false : true) ||
                        (isOpenDeploymentChecked ? true : false)
                      }
                      onChange={(e) => handleDeploymentMode(e)}
                    >
                      {DEPLOYMENT_MODE_OPTIONS.map((d, index) => {
                        return (
                          <MenuItem key={index} value={d}>
                            {d}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>
                </Grid>

                {deploymentType === DEPLOYMENT_TYPE_VALUES.ONETIME ? (
                  <Grid item>
                    <FormControl className={classes.dateFormControl}>
                      <KeyboardDatePicker
                        margin="normal"
                        id="due-date"
                        label="Due Date"
                        format="MM/dd/yyyy"
                        value={dueDate}
                        onChange={(e) => setDueDate(e)}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                    </FormControl>
                  </Grid>
                ) : (
                  ''
                )}
                {deploymentMode === DEPLOYMENT_MODE_VALUES.INTERNAL ? (
                  <>
                    <Grid item>
                      <FormControl required className={classes.formControl}>
                        <InputLabel id="deploymentMode-select-label">
                          Deployment Audience
                        </InputLabel>
                        <Select
                          labelId="deploymentAudience-select-label"
                          id="deploymentAudience-select"
                          name="deploymentMode"
                          value={deploymentAudience}
                          disabled={deploymentId === '' ? false : true}
                          onChange={handleDeploymentAudience}
                        >
                          {DEPLOYMENT_AUDIENCE_OPTIONS.map((d, index) => {
                            return (
                              <MenuItem key={index} value={d}>
                                {d}
                              </MenuItem>
                            )
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item>
                      <FormControl required className={classes.formControl}>
                        <InputLabel id="lockingDuration-select-label">
                          Locking Duration
                        </InputLabel>
                        <Select
                          labelId="lockingDuration-select-label"
                          id="lockingDuration-select"
                          name="lockingDuration"
                          value={lockingDurationFilter}
                          disabled={deploymentId === '' ? false : true}
                          onChange={handleLockingDuration}
                        >
                          {LOCKING_DURATION_FILTERS.map((d, index) => {
                            return (
                              <MenuItem key={index} value={d}>
                                {d}
                              </MenuItem>
                            )
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                  </>
                ) : (
                  <Grid item>
                    <FormControlLabel
                      className={classes.formControl}
                      control={
                        <Checkbox
                          defaultUnchecked
                          disableRipple
                          disabled={deploymentId !== '' ? true : false}
                          style={{
                            color: deploymentId !== '' ? '#9e5d5d' : '#8B0000',
                            transform: 'scale(2)',
                          }}
                          checked={
                            deploymentMode ===
                            DEPLOYMENT_MODE_VALUES.VENDOR_FACING
                              ? isArcherPullRequest
                              : false
                          }
                          onChange={handleIsArcherPullRequest}
                        />
                      }
                      label={
                        <Typography variant="h6" style={{ color: '#808080' }}>
                          Pull Survey answers from Archer
                        </Typography>
                      }
                    />
                  </Grid>
                )}
              </Grid>
              <br />
              {isOpenDeploymentChecked ? (
                deploymentId === '' ? (
                  ''
                ) : (
                  <div>
                    <Typography variant="h5">Deployed URL</Typography>
                    <pre>{env.surveyURL + '/' + deploymentId}</pre>
                  </div>
                )
              ) : (
                <>
                  {deploymentMode === DEPLOYMENT_MODE_VALUES.INTERNAL ? (
                    <div>
                      <Typography variant="h4">Custom Groups</Typography>
                      <Grid container>
                        <Grid item>
                          <FormControl
                            fullWidth
                            className={classes.formControl}
                          >
                            <InputLabel id="groupId-select-label">
                              Custom Groups
                            </InputLabel>
                            <Select
                              labelId="groupId-select-label"
                              id="groupId-select"
                              name="groupId"
                              value={groupId}
                              disabled={
                                deploymentMode ===
                                DEPLOYMENT_MODE_VALUES.VENDOR_FACING
                                  ? true
                                  : false
                              }
                              onChange={(e) => setGroupId(e.target.value)}
                              showClearButton="true"
                            >
                              <MenuItem value="">
                                <em>-None-</em>
                              </MenuItem>
                              {groupData.map((cg) => {
                                return (
                                  <MenuItem key={cg.id} value={cg.id}>
                                    {cg.name}
                                  </MenuItem>
                                )
                              })}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                      <br />

                      <Typography variant="h4">
                        Individual Recipients
                      </Typography>
                      <Grid container>
                        <Grid item>
                          <FormControl className={classes.formControl}>
                            <TextField
                              id="teamMember"
                              label="Team Member Email"
                              name="teamMember"
                              preserveWhiteSpace={true}
                              required
                              value={teamMember}
                              onChange={(e) => setTeamMember(e.target.value)}
                              multiline={true}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item>
                          <FormControl className={classes.formControl}>
                            <LoadingButton
                              variant="contained"
                              color="primary"
                              loading={isTMLoading}
                              disabled={
                                teamMember.trim().length <= 8 || isTMLoading
                              }
                              onClick={handleTeamMemberAdd}
                            >
                              Get
                            </LoadingButton>
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Grid item>
                        {teamMemberList?.length > 0 ? (
                          <RecipientTable
                            teamMemberList={teamMemberList}
                            deleteRecipient={deleteRecipient}
                          />
                        ) : (
                          ''
                        )}
                      </Grid>
                    </div>
                  ) : (
                    <>
                      {isArcherPullRequest ? (
                        ''
                      ) : (
                        <>
                          <Typography variant="h4">
                            Business Partner Assignment
                          </Typography>
                          <br />
                          {isCircularLoading ? (
                            <CircularLoading />
                          ) : bpList.length > 0 ? (
                            <BusinessPartnerTable
                              bpList={bpList}
                              addBP={addBP}
                              addSelectedBP={addSelectedBP}
                            />
                          ) : (
                            ''
                          )}

                          <br></br>
                          {teamMemberList?.length > 0 ? (
                            <AddedBusinessPartnerTable
                              teamMemberList={teamMemberList}
                              deleteRecipient={deleteRecipient}
                            />
                          ) : (
                            ''
                          )}
                        </>
                      )}
                      <br />
                    </>
                  )}

                  <br />
                </>
              )}

              {!isOpenDeploymentChecked &&
                deploymentMode === DEPLOYMENT_MODE_VALUES.INTERNAL && (
                  <>
                    <Grid Item>
                      <Typography variant="h4">Email Notification</Typography>
                      <br />
                    </Grid>
                    <Grid container>
                      <Grid item>
                        <FormControlLabel
                          className={classes.formControl}
                          control={
                            <Checkbox
                              defaultUnchecked
                              disableRipple
                              disabled={
                                deploymentMode ===
                                DEPLOYMENT_MODE_VALUES.INTERNAL
                                  ? false
                                  : true
                              }
                              style={{
                                color:
                                  deploymentMode ===
                                  DEPLOYMENT_MODE_VALUES.INTERNAL
                                    ? '#8B0000'
                                    : '#9e5d5d',
                                transform: 'scale(2)',
                              }}
                              checked={
                                deploymentMode ===
                                DEPLOYMENT_MODE_VALUES.INTERNAL
                                  ? isSendEmailChecked
                                  : false
                              }
                              onChange={handleSendEmailCheckbox}
                            />
                          }
                          label={
                            <Typography
                              variant="h6"
                              style={{ color: '#808080' }}
                            >
                              E-mail Notification
                            </Typography>
                          }
                        />
                      </Grid>

                      {isSendEmailChecked && (
                        <>
                          <Grid Item>
                            <FormControl className={classes.formControl}>
                              <TextField
                                id="teamMember"
                                label="BCC E-mails"
                                name="bccEmails"
                                value={bccEmails}
                                onChange={(e) => setBccEmails(e.target.value)}
                                multiline={true}
                              />
                            </FormControl>
                          </Grid>

                          <Grid container>
                            <Grid item style={{ width: '75%' }}>
                              <FormControl fullWidth>
                                <QuillToolbar />
                                <ReactQuill
                                  theme="snow"
                                  value={emailBody}
                                  onChange={handleEmailBodyChange}
                                  placeholder={'Enter Email here'}
                                  modules={modules}
                                  formats={formats}
                                  style={{ height: 250 }}
                                />
                              </FormControl>
                            </Grid>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </>
                )}

              <br />

              <FormControl fullWidth className={classes.formControl}>
                <LoadingButton
                  variant="contained"
                  color="primary"
                  loading={isLoading}
                  disabled={isDisabled || isReadOnly}
                  onClick={handleDeploymentLaunch}
                >
                  {deploymentId === '' ? 'Launch' : 'Re-Launch'}
                </LoadingButton>
              </FormControl>

              {deploymentId === '' ? (
                ''
              ) : (
                <>
                  <FormControl fullWidth className={classes.formControl}>
                    <LoadingButton
                      variant="contained"
                      color="primary"
                      loading={isLoading}
                      disabled={isLoading || isReadOnly}
                      onClick={handleFormChangesToDeployment}
                    >
                      Apply Changes
                    </LoadingButton>
                  </FormControl>

                  <FormControl fullWidth className={classes.formControl}>
                    <LoadingButton
                      variant="contained"
                      color="primary"
                      loading={isLoading}
                      disabled={isLoading || isReadOnly}
                      onClick={handleDeploymentComplete}
                    >
                      Complete
                    </LoadingButton>
                  </FormControl>
                </>
              )}
            </div>
          </MuiPickersUtilsProvider>
        </MuiThemeProvider>
      )}
    </React.Fragment>
  )
}

export default withStyles(styles)(CreateDeployment)
