import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import CustomTooltip from '../shared/CustomTooltip'
import AddIcon from '@material-ui/icons/Add'
import { withStyles } from '@material-ui/core/styles'

const defaultToolbarSelectStyles = {
  iconButton: {
    marginRight: '60px',
  },
  iconSize: {
    width: 25,
    height: 25,
    color: '#e21717',
    cursor: 'pointer',
  },
}

class CustomToolbarSelect extends React.Component {
  handleClick = () => {
    this.props.addSelectedUser(this.props.selectedRows)
  }

  render() {
    const { classes } = this.props

    return (
      <div className={'custom-toolbar-select'}>
        <CustomTooltip title={'Add Selected'}>
          <IconButton className={classes.iconButton} onClick={this.handleClick}>
            <AddIcon className={classes.iconSize} />
          </IconButton>
        </CustomTooltip>
      </div>
    )
  }
}

export default withStyles(defaultToolbarSelectStyles, {
  name: 'CustomToolbarSelect',
})(CustomToolbarSelect)
