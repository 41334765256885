import React, { Component } from 'react'
import { VisualizationPanel } from 'survey-analytics'
import 'survey-analytics/survey.analytics.css'
import * as Survey from 'survey-react'

class SurveyAnalytics extends Component {
  visPanel
  componentDidMount() {
    var options = {
      labelTruncateLength: 5,
      haveCommercialLicense: true, //Add this line
    }
    const { formData, surveyData } = this.props
    const survey = new Survey.SurveyModel(formData)
    var visPanel = new VisualizationPanel(
      survey.getAllQuestions(),
      surveyData,
      options,
    )
    visPanel.render(document.getElementById('summaryContainer'))
    survey.getAllQuestions().forEach(function (question) {
      var visualizer = visPanel.getVisualizer(question.name)
      visualizer.showHeader = false
      // "pie" for "dropdown", "bar" for "checkbox" and "doughnut" for "radiogroup"
      if (question.getType() === 'dropdown') {
        visualizer.setChartType('pie')
      }
      if (question.getType() === 'checkbox') {
        visualizer.setChartType('bar')
      }
      if (question.getType() === 'radiogroup') {
        visualizer.setChartType('doughnut')
      }
    })
  }
  render() {
    return (
      <React.Fragment>
        <div id="summaryContainer"></div>
      </React.Fragment>
    )
  }
}

export default SurveyAnalytics
