import React, { useState, useEffect, useContext } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import {
  getAllDeployments,
  softDeleteDeployment,
} from '../../utilities/restService'
import withStyles from '@material-ui/core/styles/withStyles'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import { Grid, Typography, FormControl } from '@material-ui/core'
import Collapse from '@material-ui/core/Collapse'
import PollIcon from '@material-ui/icons/Poll'
import CustomTooltip from '../../shared/CustomTooltip'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import TableChartIcon from '@material-ui/icons/TableChart'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import CircularLoading from '../../shared/CircularLoading'
import { formatAMAndPM, timeZone, formatedDateTime } from '../../utilities/date'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import muiTheme from '../../config/themeConfig'
import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { confirmAlert } from 'react-confirm-alert'
import { useAuth } from '@praxis/component-auth'
import {
  DEPLOYMENT_TYPE_FILTERS,
  DEPLOYMENT_TYPE_FILTERS_OPTIONS,
  STATUS,
  ROLE,
} from '../../utilities/constants'
import { ApplicationContext } from '../contexts/ApplicationContext'

const styles = (theme) => {
  return {
    root: {
      maxWidth: 300,
      minWidth: 300,
      marginLeft: 13,
      marginTop: 10,
      height: 325,
    },
    formControl: {
      margin: theme.spacing(1),
      maxWidth: 330,
      minWidth: 330,
      marginLeft: 13,
      marginBottom: 20,
    },
    selectComponent: {
      '&:before': {
        borderColor: '#8C1F27',
      },
      '&:after': {
        borderColor: '#8C1F27',
      },
      '&:hover': {
        borderColor: '#8C1F27',
        borderWidth: 2,
      },
      fontSize: '14px',
    },
    title: {
      fontSize: 13,
    },
    iconSize: {
      width: 25,
      height: 25,
    },
    margin13: {
      marginLeft: 15,
      marginTop: 4,
      marginBottom: 13,
    },
    cardContent1: {
      backgroundColor: '#EAEAEA',
    },
    cardContent2: {
      backgroundColor: '#0000',
    },
  }
}

const DeploymentPage = (props) => {
  const auth = useAuth()
  const { session } = auth
  const { classes } = props
  const [deploymentsStatus, setDeploymentsStatus] = useState(STATUS.OPEN)
  const [isLoading, setIsLoading] = useState(false)
  const [deploymentData, setDeploymentData] = useState([])
  const [deploymentFilterType, setDeploymentFilterType] = useState(
    DEPLOYMENT_TYPE_FILTERS_OPTIONS.MY_OPEN_DEPLOYMENTS,
  )
  const { setAppId, roleIdentities } = useContext(ApplicationContext)

  let { appId } = useParams()

  var filterRoles = roleIdentities
    ?.filter((r) => r.application_id.toString() === appId.toString())
    .map((r) => r.role)

  function getDeploymentsData(status) {
    setIsLoading(true)
    getAllDeployments(status)
      .then((res) => {
        setDeploymentData(res.data)
        setIsLoading(false)
      })
      .catch((error) => {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    if (appId !== undefined) {
      setAppId(appId)
    }

    getDeploymentsData(STATUS.OPEN)
  }, [appId, setAppId])

  function handleDeploymentFilter(value) {
    setDeploymentFilterType(value)
    if (value === DEPLOYMENT_TYPE_FILTERS_OPTIONS.MY_OPEN_DEPLOYMENTS) {
      getDeploymentsData(STATUS.OPEN)
      setDeploymentsStatus(STATUS.OPEN)
    } else if (
      value === DEPLOYMENT_TYPE_FILTERS_OPTIONS.MY_COMPLETED_DEPLOYMENTS
    ) {
      getDeploymentsData(STATUS.COMPLETED)
      setDeploymentsStatus(STATUS.COMPLETED)
    } else if (value === DEPLOYMENT_TYPE_FILTERS_OPTIONS.ALL_DEPLOYMENTS) {
      getDeploymentsData(STATUS.ALL)
      setDeploymentsStatus(STATUS.ALL)
    }
  }

  function deleteDeploymentId(deploymentId) {
    confirmAlert({
      title: 'Delete Deployment',
      message:
        'Are you sure you want to delete this Deployment: ' +
        deploymentId +
        '? This will delete all its completed Assessments as well',
      closeOnEscape: false,
      closeOnClickOutside: false,
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            softDeleteDeployment(deploymentId)
              .then((res) => {
                if (res.data.is_deleted === true) {
                  getDeploymentsData(deploymentsStatus)
                } else {
                  alert('Delete unsuccessful')
                }
              })
              .catch((error) => {
                alert('Delete unsuccessful')
              })
          },
        },
        {
          label: 'No',
          onClick: () => {
            return false
          },
        },
      ],
    })
  }

  return (
    <React.Fragment>
      <Helmet title="Deployments" />
      <MuiThemeProvider theme={muiTheme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid container>
            <Grid item xs={11}>
              <Typography variant="h4" className={classes.margin13}>
                Deployment Details
              </Typography>
            </Grid>

            <Grid item>
              {filterRoles?.includes(ROLE.ADMIN) && (
                <CustomTooltip title={'Create Deployment'}>
                  <Link
                    color={'primary'}
                    to={{
                      pathname: '/application/' + appId + '/deploymentCreator',
                    }}
                    style={{ textDecoration: 'none' }}
                  >
                    <AddCircleIcon className={classes.iconSize} />
                  </Link>
                </CustomTooltip>
              )}
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={2} className={classes.margin13}>
              <FormControl fullWidth>
                <Select
                  className={classes.selectComponent}
                  labelId="deploymentFilterType-select-label"
                  id="deploymentFilterType-select"
                  name="deploymentFilterType"
                  value={deploymentFilterType}
                  onChange={(e) => handleDeploymentFilter(e.target.value)}
                >
                  {DEPLOYMENT_TYPE_FILTERS.map((d, index) => {
                    return (
                      <MenuItem key={index} value={d}>
                        {d}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>

      {isLoading ? (
        <CircularLoading />
      ) : (
        <Grid container>
          {deploymentData.map((deployment, index) => {
            return (
              <Grid item key={index}>
                <Card className={classes.root}>
                  <Collapse
                    style={{ backgroundColor: '#aaa' }}
                    collapsedHeight={'5.6em'}
                    timeout="auto"
                    title={deployment.name}
                  >
                    <CardContent>
                      <Typography
                        variant="h1"
                        style={{
                          color: '#333',
                          fontSize: '17px',
                          fontWeight: 'bold',
                        }}
                      >
                        {deployment.name !== null &&
                        deployment.name !== undefined &&
                        deployment.name !== ''
                          ? deployment.name.length > 70
                            ? deployment.name.slice(0, 70) + '...'
                            : deployment.name
                          : ''}
                      </Typography>
                    </CardContent>
                  </Collapse>
                  <CardContent
                    className={
                      deployment.status === STATUS.COMPLETED
                        ? classes.cardContent1
                        : classes.cardContent2
                    }
                  >
                    <Typography className={classes.title}>
                      Deployment Id: {deployment.id}
                    </Typography>
                    <Typography className={classes.title}>
                      Survey Name:{' '}
                      {deployment.survey_name !== null &&
                      deployment.survey_name !== undefined &&
                      deployment.survey_name !== '' ? (
                        <span title={deployment.survey_name}>
                          {deployment.survey_name.length > 21
                            ? deployment.survey_name.slice(0, 21) + '...'
                            : deployment.survey_name}
                        </span>
                      ) : (
                        ''
                      )}
                    </Typography>
                    <Typography className={classes.title}>
                      Status: {deployment.status}
                    </Typography>
                    <Typography className={classes.title}>
                      Type: {deployment.deployment_type}
                    </Typography>
                    <Typography className={classes.title}>
                      Mode: {deployment.deployment_mode}
                    </Typography>
                    <Typography className={classes.title}>
                      Created on:{' '}
                      {deployment.created_date === null
                        ? ''
                        : formatAMAndPM(
                            new Date(
                              formatedDateTime(
                                new Date(deployment.created_date),
                              ) + timeZone,
                            ),
                          )}
                    </Typography>
                    <Typography className={classes.title}>
                      Created by: {deployment.created_by}
                    </Typography>
                    <Typography className={classes.title}>
                      Modified on:{' '}
                      {deployment.modified_date === null
                        ? ''
                        : formatAMAndPM(
                            new Date(
                              formatedDateTime(
                                new Date(deployment.modified_date),
                              ) + timeZone,
                            ),
                          )}
                    </Typography>
                    <Typography className={classes.title}>
                      Modified by: {deployment.modified_by}
                    </Typography>
                  </CardContent>
                  {filterRoles?.includes(ROLE.ADMIN) ? (
                    <CardActions
                      className={
                        deployment.status === STATUS.COMPLETED
                          ? classes.cardContent1
                          : classes.cardContent2
                      }
                    >
                      <CustomTooltip title={'Edit'}>
                        <Link
                          color={'primary'}
                          to={{
                            pathname:
                              '/application/' +
                              appId +
                              '/deploymentUpdate/' +
                              deployment.id,
                          }}
                          style={{ textDecoration: 'none' }}
                        >
                          <EditIcon className={classes.iconSize} />
                        </Link>
                      </CustomTooltip>
                      <CustomTooltip title={'Analytics'}>
                        <Link
                          color={'primary'}
                          to={{
                            pathname:
                              '/application/' +
                              appId +
                              '/analytics/' +
                              deployment.id,
                          }}
                          style={{ textDecoration: 'none' }}
                        >
                          <PollIcon className={classes.iconSize} />
                        </Link>
                      </CustomTooltip>
                      <CustomTooltip title={'Analytics Tabulator'}>
                        <Link
                          color={'primary'}
                          to={{
                            pathname:
                              '/application/' +
                              appId +
                              '/analyticstabulator/' +
                              deployment.id,
                          }}
                          style={{ textDecoration: 'none' }}
                        >
                          <TableChartIcon className={classes.iconSize} />
                        </Link>
                      </CustomTooltip>
                      {deployment.created_by === session?.userInfo?.lanId ||
                      (deployment.modified_by != null &&
                        deployment.modified_by === session?.userInfo?.lanId) ? (
                        <CustomTooltip title={'Delete Deployment'}>
                          <Link
                            color={'primary'}
                            style={{ textDecoration: 'none' }}
                          >
                            <DeleteIcon
                              className={classes.iconSize}
                              onClick={(e) => {
                                e.preventDefault()
                                deleteDeploymentId(deployment.id)
                              }}
                            />
                          </Link>
                        </CustomTooltip>
                      ) : (
                        ''
                      )}
                    </CardActions>
                  ) : (
                    <CardActions
                      className={
                        deployment.status === STATUS.COMPLETED
                          ? classes.cardContent1
                          : classes.cardContent2
                      }
                    >
                      <CustomTooltip title={'Analytics'}>
                        <Link
                          color={'primary'}
                          to={{
                            pathname:
                              '/application/' +
                              appId +
                              '/analytics/' +
                              deployment.id,
                          }}
                          style={{ textDecoration: 'none' }}
                        >
                          <PollIcon className={classes.iconSize} />
                        </Link>
                      </CustomTooltip>
                      <CustomTooltip title={'Analytics Tabulator'}>
                        <Link
                          color={'primary'}
                          to={{
                            pathname:
                              '/application/' +
                              appId +
                              '/analyticstabulator/' +
                              deployment.id,
                          }}
                          style={{ textDecoration: 'none' }}
                        >
                          <TableChartIcon className={classes.iconSize} />
                        </Link>
                      </CustomTooltip>
                    </CardActions>
                  )}
                </Card>
              </Grid>
            )
          })}
        </Grid>
      )}
    </React.Fragment>
  )
}

export default withStyles(styles)(DeploymentPage)
