/*
👋 “Hey” from the Praxis team
Praxis ships with support for modern browsers, but you may have users or external vendors that require IE11.
If that’s the case - you need to do two things:
- [1] change the jsxRuntime pragma value from "automatic" value to "classic". It can remain a comment, babel will read it.
      @jsxRuntime automatic
- [2] uncomment the below block between "PRAXIS IE11 START" and "PRAXIS IE11 END".
Learn more about our the default browser support in the README or at http://go/praxis-browser-support.
*/
/* PRAXIS IE11 START */
// import '@praxis/react-app-polyfill/ie11'
// import '@praxis/react-app-polyfill/stable'
// // eslint-disable-next-line no-unused-vars
// import React from 'react'
/* PRAXIS IE11 END */

import ReactDOM from 'react-dom'
import { onError, reportWebVitals } from '@praxis/component-logging'
import { EnvProvider } from '@praxis/component-runtime-env'
import commonConfig from './config/commonConfig'
import App from './App'
import './index.css'

window.onerror = onError

// If you want to start measuring performance in your app, enable the reportWebVitals()
// function below. Learn more: https://praxis.prod.target.com/components/logging#quick-start-metrics
reportWebVitals()

ReactDOM.render(
  <EnvProvider
    commonConfig={commonConfig}
    configPath={
      process.env.NODE_ENV === 'development'
        ? '/config.json'
        : '/app-environment'
    }
  >
    <App />
  </EnvProvider>,
  document.getElementById('root'),
)
