import React from 'react'
import axios from 'axios'
import CustomizedSnackbars from '../../shared/CustomizedSnackbars'
import moment from 'moment-timezone/builds/moment-timezone-with-data'

export class Interceptor extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      authInterceptor: undefined,
      errorInterceptor: undefined,
      isError: false,
      message: '',
    }
  }

  componentDidMount() {
    this.sessionTimeOutCheck()

    this.addAuthInterceptor()
    this.addErrorInterceptor()
  }

  sessionTimeOutCheck() {
    if (this.props.session && this.props.session?.userInfo) {
      if (this.props.session?.access) {
        if (this.props.session?.hasExpired) {
          this.setState({
            isError: true,
            message: 'Session is expired. Please re-login',
          })
          this.props.logout()
        }
        const tokenExpires = this.props.session?.access?.exp

        this.warningTimeout = setTimeout(
          () => {
            this.setState({
              isError: true,
              message: 'Session expires in few minutes',
            })
          },
          tokenExpires * 1000 - moment().valueOf() - 4200000,
        )
        this.logoutTimeout = setTimeout(
          () => {
            this.props.logout()
          },
          tokenExpires * 1000 - moment().valueOf() - 3600000,
        )
      }
    }
  }

  componentWillUnmount() {
    if (this.warningTimeout) {
      clearTimeout(this.warningTimeout)
    }
    if (this.logoutTimeout) {
      clearTimeout(this.logoutTimeout)
    }
    this.removeAuthInterceptor()
    this.removeErrorInterceptor()
  }

  addAuthInterceptor = () => {
    const authInterceptor = axios.interceptors.request.use(
      (config) => {
        if (this.props.session?.hasExpired) {
          this.props.logout()
        }
        if (!config.headers.common['Authorization']) {
          if (this.props.session?.userInfo?.accessToken) {
            config.headers.Authorization =
              this.props.session?.userInfo?.accessToken
            // config.headers.Authorization = "Bearer eyJraWQiOiJFLlBSRC4wMUc2QzkxRVdSRTZCWSIsImFsZyI6IlJTMjU2IiwidHlwIjoiSldUIn0.eyJzdWIiOiJaMDAySjVOIiwiaXNzIjoiaHR0cHM6Ly9vYXV0aC5pYW0udGFyZ2V0LmNvbSIsImV4cCI6MTY2ODE3ODYwNCwiaWF0IjoxNjY4MTQ5ODA0LCJqdGkiOiJFTlQuNmNiMjVlOWJlOTI0NDM0MDhmODIyZmMwNTNmZjdjOWQtbCIsInNreSI6IkUuUFJELjAxRzZDOTFFV1JFNkJZIiwic3V0IjoiUiIsImVpZCI6IiIsInNjbyI6Im9wZW5pZCxwcm9maWxlIiwiY2xpIjoidGZhdF9wcm9kX2ltIiwiYXNsIjoiTCIsInVzZXJuYW1lIjoiWjAwMko1TiIsImtpZCI6IkUuUFJELjAxRzZDOTFFV1JFNkJZIiwic2NvcGUiOlsib3BlbmlkIiwicHJvZmlsZSJdLCJhYWwiOiIxIiwiY2xpZW50X2lkIjoidGZhdF9wcm9kX2ltIiwiZGlyIjoiY29ycCIsIm5vbmNlIjoiT2k3MnYyTlRCcldHUk9kMFpWLUQ1In0.JyRq-zkVIX_yh1PpUQBH0oNM3-flznbnuLaQbXUdlryEaA59MLiqUEOkUt80k-Z-pxVT8ES8Ttq5Hw5c3VH4GfR5LXyaTGXNCZcQbZDtrHNVEBgWIjg4LEe4Su_bDrZc1pJCZM5HkSUsEiqs_QkmU89G8PjYhyQ7tRMR7juT_5_89PUt8lOLGxdpzOe6Jp_wIEBabqhrhHx-tFBap0quVFsCiEsz6HCy0_uLV6gdmUpsQX7XmnRlX54fHOSwimEmr_jE1i71-aQ2fyaxtttWE2atc8dpgJkISFuPC_VNmdf-A8FFY4cyk_8SWbPbrhE4wQogNv6Y8TzuknHwHBNl1A"
          }
        } else if (!config.headers.Authorization) {
          delete config.headers.Authorization
        }
        config.headers['x-api-key'] = this.props.env?.key
        config.headers['application-id'] = this.props.appId
        config.headers['X-TGT-LANID'] = this.props.session?.userInfo?.lanId
        config.headers['X-Tgt-Mail'] = this.props.session?.userInfo?.email
        // config.headers['X-TGT-MemberOf'] =
        //   'CN=APP-DEM-Stores-ADMIN-NP,OU=Application,OU=Groupings,DC=corp,DC=target,DC=com'

        return config
      },
      (error) => {
        return Promise.reject(error)
      },
    )
    this.setState({ authInterceptor })
  }

  removeAuthInterceptor = () => {
    axios.interceptors.request.eject(this.state.authInterceptor)
    this.setState({ authInterceptor: undefined })
  }

  addErrorInterceptor = () => {
    const errorInterceptor = axios.interceptors.response.use(
      (response) => {
        return response
      },
      (error) => {
        if (error.response) {
          this.setState({
            isError: false,
          })
          const response = error.response
          const code = response.status
          let message = 'Something went wrong.'
          if (code === 401) {
            message = 'Session is expired. Please re-login'
          } else {
            if (code === 403) {
              message = 'You’re not authorized to do that.'
            } else if (code === 504) {
              message = 'Something went wrong.'
            } else if (code === 415) {
              message = 'Please upload only Images.'
            } else if (
              code === 500 &&
              error?.response?.data !== null &&
              error?.response?.data !== undefined &&
              error?.response?.data !== '' &&
              error?.response?.data.message?.includes('invalid')
            ) {
              message = error.response.data.message
            } else if (
              error?.response?.data.message?.includes('invalid email')
            ) {
              message = error.response.data.message
            }
          }
          this.setState({
            isError: true,
            message: message,
          })
        }
        return Promise.reject(error)
      },
    )
    this.setState({ errorInterceptor })
  }

  removeErrorInterceptor = () => {
    axios.interceptors.request.eject(this.state.errorInterceptor)
    this.setState({ errorInterceptor: undefined })
  }

  render() {
    const { isError, message } = this.state
    return (
      <React.Fragment>
        {isError ? (
          <CustomizedSnackbars variant="error" message={message} />
        ) : (
          ''
        )}
      </React.Fragment>
    )
  }
}
export default Interceptor
