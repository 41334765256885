import format from 'date-fns/fp/format'

const timeFormat = 'HH:mm'
const dateFormat = 'MM-dd-yyyy'
const dateTimeFormat = 'MM-dd-yyyy HH:mm:ss'
const dateTimeSecondFormat = "yyyy-MM-dd'T'HH:mm:ss"
const dateTimeFormatAMAndPM = 'MM-dd-yyyy hh:mm a'

export const formatTime = format(timeFormat)
export const formatDate = format(dateFormat)
export const formatDateTime = format(dateTimeFormat)
export const formatedDateTime = format(dateTimeSecondFormat)
export const formatAMAndPM = format(dateTimeFormatAMAndPM)
export const timeZone = '.000Z'
