import 'date-fns'
import React from 'react'
import { Helmet } from 'react-helmet'
import withStyles from '@material-ui/core/styles/withStyles'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import muiTheme from '../../config/themeConfig'
import MUIDataGrid from '../../shared/MUIDataGrid'
import CustomToolbarBPSelect from '../deployment/CustomToolbarBPSelect'
import AddIcon from '@material-ui/icons/Add'

const styles = (theme) => ({
  iconSize: {
    width: 25,
    height: 25,
    color: '#e21717',
    cursor: 'pointer',
  },
  margin13: {
    marginLeft: 13,
    marginTop: 4,
  },
})

const BusinessPartnerTable = (props) => {
  const { classes, bpList, addBP, addSelectedBP } = props
  const BUSINESS_PARTNER_COLUMNS = [
    {
      name: 'id',
      label: 'Id',
      options: {
        display: false,
        filter: false,
      },
    },
    {
      name: 'bp_id',
      label: 'BP Id',
      options: {
        display: true,
        filter: false,
      },
    },
    {
      name: 'bp_name',
      label: 'BP Name',
      options: {
        filter: false,
      },
    },
    {
      name: 'id',
      label: 'Actions',
      options: {
        filter: false,
        download: false,
        customBodyRender: (value) => (
          <AddIcon
            className={classes.iconSize}
            onClick={(e) => {
              e.preventDefault()
              addBP(value)
            }}
          />
        ),
      },
    },
  ]

  const options = {
    rowsPerPage: 5,
    filter: true,
    selectableRows: 'multiple',
    filterType: 'dropdown',
    responsive: 'standard',
    fixedHeader: true,
    tableBodyHeight: '35vh',
    rowsPerPageOptions: [5, 20, 50, 100],
    rowHover: true,
    sort: true,
    print: false,
    viewColumns: true,
    download: false,
    search: true,
    customToolbarSelect: (selectedRows) => (
      <CustomToolbarBPSelect
        selectedRows={selectedRows}
        addSelectedBP={addSelectedBP}
      />
    ),
  }

  return (
    <React.Fragment>
      <Helmet title="Assign deployment to Business Partner" />
      <div>
        <Grid container>
          <Grid item xs={11}></Grid>
          <Grid item />
        </Grid>
        <MuiThemeProvider theme={muiTheme}>
          <MUIDataGrid
            title="Business Partner Data"
            rows={bpList}
            columns={BUSINESS_PARTNER_COLUMNS}
            options={options}
          />
        </MuiThemeProvider>
      </div>
    </React.Fragment>
  )
}

export default withStyles(styles)(BusinessPartnerTable)
