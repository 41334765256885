import React, { useState, useEffect, useContext } from 'react'
import SurveyAnalytics from './SurveyAnalytics'
import {
  getSurveryResponseDataByDeploymentId,
  getFormByDeploymentId,
} from '../../utilities/restService'
import { CustomQuestions } from '../surveyAnswer/CustomQuestions'
import { ApplicationContext } from '../contexts/ApplicationContext'
import { useParams } from 'react-router-dom'

const AnalyticsPage = (props) => {
  const [isFormLoading, setIsFormLoading] = useState(false)
  const [formData, setFormData] = useState({})
  const [isSurveyLoading, setIsSurveyLoading] = useState(false)
  const [surveyData, setSurveyData] = useState([])
  const { setAppId } = useContext(ApplicationContext)

  let { appId, id } = useParams()

  useEffect(() => {
    if (appId !== undefined) {
      setAppId(appId)
    }
  }, [appId, setAppId])

  useEffect(() => {
    function getFormDataById() {
      if (id !== undefined) {
        setIsFormLoading(true)
        getFormByDeploymentId(id)
          .then((res) => {
            setFormData(res.data?.survey_form_json)
            setIsFormLoading(false)
          })
          .catch((error) => {
            setIsFormLoading(false)
          })
      }
    }

    function getSurveyResponseByFormId() {
      if (id !== undefined) {
        setIsSurveyLoading(true)
        getSurveryResponseDataByDeploymentId(id)
          .then((res) => {
            setSurveyData(res.data.map((s) => s.survey_answer_json))
            setIsSurveyLoading(false)
          })
          .catch((error) => {
            setIsSurveyLoading(false)
          })
      }
    }
    if (Object.keys(formData).length === 0) {
      getFormDataById()
      getSurveyResponseByFormId()
    }
  }, [id, formData])
  return (
    <React.Fragment>
      <div style={{ margin: '20px' }}>
        <h3>Visualize field assessment results</h3>
        {!isFormLoading && !isSurveyLoading && appId !== 0 ? (
          <div>
            <CustomQuestions />
            <SurveyAnalytics formData={formData} surveyData={surveyData} />
          </div>
        ) : (
          'loading ....'
        )}
      </div>
    </React.Fragment>
  )
}

export default AnalyticsPage
