import React from 'react'
import PropTypes from 'prop-types'

import SignOutIcon from '@material-ui/icons/ExitToApp'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import Avatar from '@material-ui/core/Avatar'

import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { useAuth } from '@praxis/component-auth'

const styles = (theme) => ({
  avator: {
    color: '#BF2C2C',
    backgroundColor: '#ffff',
    width: '30px',
    height: '30px',
    fontSize: 'medium',
  },
  iconFontSize: {
    fontSize: '2.5rem',
  },
  textFontSize: {
    fontSize: '13px',
  },
})

export const User = ({ classes }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const auth = useAuth() // connect to the \`AuthContext\` provided by \`App\` below, using a React hook.
  const { isAuthorized, logout, session } = auth // assign variables of same name as properties off of \`auth\`

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  return isAuthorized ? (
    <React.Fragment>
      <Typography variant="subtitle1">
        <div className="userName" onClick={handleClick}>
          {session?.userInfo?.firstName !== undefined &&
          session?.userInfo?.lastName !== undefined ? (
            <Avatar
              className={classes.avator}
              title={`${session.userInfo.firstName} ${session.userInfo.lastName}`}
            >
              <span>
                {session.userInfo.firstName.charAt(0) +
                  session.userInfo.lastName.charAt(0)}
              </span>
            </Avatar>
          ) : (
            <Avatar
              className={classes.avator}
              title={`${session.userInfo.firstName} ${session.userInfo.lastName}`}
            ></Avatar>
          )}
        </div>
      </Typography>
      <div>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          PaperProps={{
            style: {
              marginTop: '50px',
              width: '200px',
            },
          }}
        >
          <MenuItem onClick={logout}>
            <SignOutIcon className={classes.iconFontSize} />
            <div className={classes.textFontSize}>Logout</div>
          </MenuItem>
        </Menu>
      </div>
    </React.Fragment>
  ) : (
    ''
  )
}

User.propTypes = {
  classes: PropTypes.object,
}

export default withStyles(styles)(User)
