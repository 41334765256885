import 'date-fns'
import React from 'react'
import { Helmet } from 'react-helmet'
import withStyles from '@material-ui/core/styles/withStyles'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import muiTheme from '../../config/themeConfig'
import MUIDataGrid from '../../shared/MUIDataGrid'
import DeleteIcon from '@material-ui/icons/Delete'

const styles = (theme) => ({
  iconSize: {
    width: 25,
    height: 25,
    color: '#e21717',
    cursor: 'pointer',
  },
  margin13: {
    marginLeft: 13,
    marginTop: 4,
  },
})

const RecipientTable = (props) => {
  const { classes, teamMemberList, deleteRecipient } = props

  // Recipient Columns
  const RECIPIENT_COLUMNS = [
    {
      name: 'id',
      label: 'Id',
      options: {
        display: false,
      },
    },
    {
      name: 'lan_id',
      label: 'LanId',
      options: {
        filter: false,
      },
    },
    {
      name: 'first_name',
      label: 'First Name',
      options: {
        filter: false,
      },
    },
    {
      name: 'last_name',
      label: 'Last Name',
      options: {
        filter: false,
      },
    },
    {
      name: 'email_id',
      label: 'Email ID',
      options: {
        filter: true,
      },
    },
    {
      name: 'role',
      label: 'Role',
      options: {
        filter: true,
      },
    },
    {
      name: 'location_id',
      label: 'Location ID',
      options: {
        filter: true,
      },
    },
    {
      name: 'region',
      label: 'Region',
      options: {
        filter: true,
      },
    },
    {
      name: 'location_group',
      label: 'Group',
      options: {
        filter: true,
      },
    },
    {
      name: 'work_state',
      label: 'State',
      options: {
        filter: true,
      },
    },
    {
      name: 'id',
      label: 'Actions',
      options: {
        filter: true,
        download: false,
        customBodyRender: (value) => (
          <DeleteIcon
            className={classes.iconSize}
            onClick={(e) => {
              e.preventDefault()
              deleteRecipient(value)
            }}
          />
        ),
      },
    },
  ]

  const options = {
    rowsPerPage: 25,
    filterType: 'dropdown',
    responsive: 'standard',
    fixedHeader: true,
    tableBodyHeight: '35vh',
    rowsPerPageOptions: [25, 50, 100, 200],
    rowHover: true,
    sort: true,
    filter: true,
    print: false,
    viewColumns: true,
    download: false,
    search: true,
    selectableRows: 'none',
  }

  return (
    <React.Fragment>
      <Helmet title="Create Group" />
      <div>
        <Grid container>
          <Grid item xs={11}></Grid>
          <Grid item />
        </Grid>
        <MuiThemeProvider theme={muiTheme}>
          <MUIDataGrid
            title="Recipient data"
            rows={teamMemberList}
            columns={RECIPIENT_COLUMNS}
            options={options}
          />
        </MuiThemeProvider>
      </div>
    </React.Fragment>
  )
}

export default withStyles(styles)(RecipientTable)
