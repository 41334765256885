import withStyles from '@material-ui/core/styles/withStyles'
import Tooltip from '@material-ui/core/Tooltip'

const CustomTooltip = withStyles({
  tooltip: {
    fontSize: '10px',
  },
})(Tooltip)

export default CustomTooltip
