import React, { useState, useEffect } from 'react'
import { useAuth } from '@praxis/component-auth'
import { useParams, useLocation } from 'react-router-dom'
import * as Survey from 'survey-react'
import * as widgets from 'surveyjs-widgets'
import 'survey-react/survey.css'
import CryptoJS from 'crypto-js'

import 'jquery-ui/themes/base/all.css'
import 'nouislider/distribute/nouislider.css'
import 'select2/dist/css/select2.css'
import 'bootstrap-slider/dist/css/bootstrap-slider.css'

import 'jquery-bar-rating/dist/themes/css-stars.css'

import $ from 'jquery'
import 'jquery-ui/ui/widgets/datepicker.js'
import 'select2/dist/js/select2.js'
import 'jquery-bar-rating'

import 'pretty-checkbox/dist/pretty-checkbox.css'
import CircularLoading from '../../shared/CircularLoading'

import {
  getFormAndAnswerByDeploymentId,
  createSurveryResponse,
  uploadFile,
  downloadFile,
  getAllLocations,
  updateSurveryResponse,
  getRecipientsByEmailId,
} from '../../utilities/restService'
import { useEnv } from '@praxis/component-runtime-env'
import '../../utilities/QuillEditortcustomwidget'
import '../../utilities/customMethods'
import { Grid, Typography } from '@material-ui/core'
import { v4 as uuid } from 'uuid'

window['$'] = window['jQuery'] = $

Survey.StylesManager.ThemeCss['.sv_main .sv-action-bar'] =
  'display: flex;position: relative;align-items: center;margin-left: auto;overflow: hidden;white-space: nowrap;'

Survey.StylesManager.applyTheme('default')

widgets.prettycheckbox(Survey)
widgets.select2(Survey, $)
widgets.inputmask(Survey)
widgets.jquerybarrating(Survey, $)
widgets.jqueryuidatepicker(Survey, $)
widgets.nouislider(Survey)
widgets.select2tagbox(Survey, $)
widgets.sortablejs(Survey)
widgets.ckeditor(Survey)
widgets.autocomplete(Survey, $)
widgets.bootstrapslider(Survey)

var locationsData = []
var surveyAnswerId = null

Survey.Serializer.addProperty('survey', {
  name: 'include_save_button',
  displayName: 'Include save button?',
  type: 'boolean',
  default: true,
  category: 'general',
  visibleIndex: 3,
})

Survey.Serializer.addProperty('survey', {
  name: 'finding_description',
  displayName: 'Finding Description',
  type: 'text',
  category: 'general',
  visibleIndex: 5,
})

//Add property
Survey.Serializer.addProperty('question', {
  name: 'include_pdf',
  displayName: 'Include in PDF?',
  type: 'boolean',
  default: true,
  category: 'general',
  visibleIndex: 3,
})

//Add property
Survey.Serializer.addProperty('question', {
  name: 'role',
  default: 'user',
  choices: ['user', 'user-readOnly', 'admin', 'admin-user'],
  category: 'general',
})

Survey.Serializer.addProperty('question', {
  name: 'create_finding',
  displayName: 'Create Finding',
  type: 'boolean',
  default: false,
  category: 'general',
  visibleIndex: 4,
})

Survey.Serializer.addProperty('question', {
  name: 'finding_action',
  displayName: 'Finding Action',
  type: 'text',
  category: 'general',
  visibleIndex: 5,
})

//Add property
Survey.Serializer.addProperty('question', {
  name: 'includePdfIf:condition',
  category: 'logic',
})
Survey.Serializer.addProperty('question', {
  name: 'createFindingIf:condition',
  category: 'logic',
})

function isNotEmpty(value) {
  return value !== undefined && value !== null && value?.length !== 0
}

function setPdfFlagTrue(params) {
  if (params.length < 1) return
  params[0].isPdfSend = true
  return params[0]
}
Survey.FunctionFactory.Instance.register('setPdfFlagTrue', setPdfFlagTrue)

function setEmailFlagTrue(params) {
  if (params.length < 1) return
  params[0].isEmailSend = true
  params[0].isPdfSend = true
  return params[0]
}
Survey.FunctionFactory.Instance.register('setEmailFlagTrue', setEmailFlagTrue)

function setPdfFlagFalse(params) {
  if (params.length < 1) return
  params[0].isPdfSend = false
  return params[0]
}
Survey.FunctionFactory.Instance.register('setPdfFlagFalse', setPdfFlagFalse)

function setEmailFlagFalse(params) {
  if (params.length < 1) return
  params[0].isEmailSend = false
  params[0].isPdfSend = false
  return params[0]
}
Survey.FunctionFactory.Instance.register('setEmailFlagFalse', setEmailFlagFalse)

// Include PDF Question Methods
function runIncludePdfIf(values, properties, question) {
  if (!question.includePdfIf) return
  const conditionRunner = new Survey.ConditionRunner(question.includePdfIf)
  conditionRunner.expression = question.includePdfIf
  conditionRunner.onRunComplete = (res) => {
    question.include_pdf = res
  }
  conditionRunner.run(values, properties)
}

// Create Finding Question Methods
function runCreateFindingIf(values, properties, question) {
  if (!question.createFindingIf) return
  const conditionRunner = new Survey.ConditionRunner(question.createFindingIf)
  conditionRunner.expression = question.createFindingIf
  conditionRunner.onRunComplete = (res) => {
    question.create_finding = res
  }
  conditionRunner.run(values, properties)
}

const calculateMD5CheckSum = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = (event) => {
      const arrayBuffer = event.target.result
      const wordArray = CryptoJS.lib.WordArray.create(arrayBuffer)
      const md5Checksum = CryptoJS.MD5(wordArray).toString(CryptoJS.enc.Hex)
      resolve(md5Checksum)
    }

    reader.onerror = (error) => {
      reject(error)
    }

    reader.readAsArrayBuffer(file)
  })
}

export function SurveyAnswer(props) {
  const [isLoading, setIsLoading] = useState(false)
  const [model, setModel] = useState({})
  const [surveyAnswer, setSurveyAnswer] = useState({})
  const [allLocations, setAllLocations] = useState([])
  const [deploymentType, setDeploymentType] = useState('')
  const [deploymentMode, setDeploymentMode] = useState('')
  const [deploymentStatus, setDeploymentStatus] = useState('')
  const [isSurveyLocked, setIsSurveyLocked] = useState('')
  const [supervisorEmail, setSupervisorEmail] = useState('')
  const auth = useAuth() // connect to the \`AuthContext\` provided by \`App\` below, using a React hook.
  const { session } = auth // assign variables of same name as properties off of \`auth\`
  const env = useEnv()
  var { answerId, deploymentId } = useParams()
  var urlLocation = useLocation()

  const hasAccessAdminGroup = session.userInfo.memberOf.includes(
    env.access.adminAccessAdGroup,
  )

  // Survey.StylesManager.applyTheme("winter");
  useEffect(() => {
    Survey.ComponentCollection.Instance.clear()
    if (deploymentId !== undefined) {
      surveyAnswerId = answerId
      setIsLoading(true)
      getAllLocations().then((res) => {
        setAllLocations(res?.data)
        locationsData = res?.data
      })
      getFormAndAnswerByDeploymentId(deploymentId, surveyAnswerId)
        .then((res) => {
          surveyAnswerId = res.data?.survey_answer_id
          setModel(res.data?.survey_form_json)
          setSurveyAnswer(res.data?.survey_answer_response)
          setDeploymentType(res.data?.deployment_type)
          setDeploymentMode(res.data?.deployment_mode)
          setDeploymentStatus(res.data?.deployment_status)
          setIsSurveyLocked(res.data?.is_survey_locked)
          setIsLoading(false)
        })
        .catch((error) => {
          setIsLoading(false)
        })
    }
  }, [deploymentId, answerId, session])

  useEffect(() => {
    getRecipientsByEmailId(session?.userInfo?.email)
      .then((res) => {
        setSupervisorEmail(res.data?.supervisor_email)
      })
      .catch((error) => {
        setIsLoading(false)
      })
  }, [session?.userInfo?.email])

  function onPartialSend(sender, options) {
    saveSurveyAnswers(sender, deploymentId, deploymentType, false)
  }

  function onDynamicPanelAdded(sender, options) {
    const unique_id = uuid()
    options.panel.questions.forEach(function (question) {
      if (question?.name === env?.demeterSubFormUniqueId) {
        question.value = unique_id
        question.readOnly = true
      }
    })
  }

  function onCompleting(sender, options) {
    sender.getAllQuestions().forEach(function (question) {
      if (question?.getType() === 'quilleditor') {
        if (
          question.value !== undefined &&
          question.value !== null &&
          question.value?.includes('<img') &&
          question.value?.includes('src=')
        ) {
          alert(
            question.name +
              ' : question contains image, kindly remove the image before completing assessment',
          )
          options.allow = false
          options.allowComplete = false
        }
      }
    })
  }

  function onComplete(sender, options) {
    options.showDataSaving()
    var values = survey.getFilteredValues()
    var properties = survey.getFilteredProperties()

    var result = sender?.data

    var request = {
      deployment_id: deploymentId,
      status: 'Completed',
      deployment_type: deploymentType,
      finding_description: survey?.finding_description,
    }
    var pdfContent = '<h1>Hello, </h1>'
    var emailQuestionData = []
    var action_items = []
    sender.getAllQuestions().forEach(function (question) {
      runIncludePdfIf(values, properties, question) // Run includePDFIF Expression
      runCreateFindingIf(values, properties, question) // Run createFindingIf Expression

      if (question?.getType() === 'emailnotification') {
        if (question.value !== undefined && question.value !== null) {
          var emailResult = question?.value
          var to_email_Matches = emailResult?.to_email?.match(/[^{]+(?=})/g)
          var to_email =
            emailResult?.to_email !== undefined &&
            emailResult?.to_email !== null
              ? UpdateEmailBodyMatchedQuestionValues(
                  to_email_Matches,
                  result,
                  sender,
                  emailResult?.to_email,
                )
              : ''

          var body_email_Matches = emailResult?.body_email?.match(/[^{]+(?=})/g)
          var body_email =
            emailResult?.body_email !== undefined &&
            emailResult?.body_email !== null
              ? UpdateEmailBodyMatchedQuestionValues(
                  body_email_Matches,
                  result,
                  sender,
                  emailResult?.body_email,
                )
              : ''

          var subject_email_Matches =
            emailResult?.subject_email?.match(/[^{]+(?=})/g)
          var subject_email =
            emailResult?.subject_email !== undefined &&
            emailResult?.subject_email !== null
              ? UpdateEmailBodyMatchedQuestionValues(
                  subject_email_Matches,
                  result,
                  sender,
                  emailResult?.subject_email,
                )
              : ''

          var bcc_email_Matches = emailResult?.bcc_email?.match(/[^{]+(?=})/g)
          var bcc_email =
            emailResult?.bcc_email !== undefined &&
            emailResult?.bcc_email !== null
              ? UpdateEmailBodyMatchedQuestionValues(
                  bcc_email_Matches,
                  result,
                  sender,
                  emailResult?.bcc_email,
                )
              : ''

          if (emailResult?.isEmailSend) {
            var emailObj = {
              bcc_email: bcc_email,
              body_email: body_email,
              is_send_email: emailResult?.isEmailSend,
              is_send_pdf: emailResult?.isPdfSend,
              subject_email: subject_email,
              to_email: to_email,
            }
            emailQuestionData.push(emailObj)
          }
          Object.assign(request, {
            email_notification_request_list: emailQuestionData,
          })
        }
      }
      if (question?.title === 'To') {
        updateEmailContent(question, result, request, 'to_email', sender)
      } else if (question?.title === 'BCC') {
        updateEmailContent(question, result, request, 'bcc_email', sender)
      } else if (question?.title === 'Subject') {
        updateEmailContent(question, result, request, 'subject_email', sender)
      } else if (question?.title === 'Email body') {
        updateEmailContent(question, result, request, 'body_email', sender)
      } else if (question?.title === 'Send Email?') {
        Object.assign(request, { is_send_email: question?.value })
      } else if (question?.title === 'Send PDF?') {
        Object.assign(request, { is_send_pdf: question?.value })
      } else if (
        question?.getType() !== 'imagepicker' &&
        question?.getType() !== 'image' &&
        question?.getType() !== 'signaturepad' &&
        question?.getType() !== 'paneldynamic' &&
        question?.getType() !== 'emailnotification'
      ) {
        if (
          (question?.getType() === 'matrixdynamic' ||
            question?.getType() === 'matrixdropdown') &&
          question?.include_pdf
        ) {
          pdfContent += '<p><b>' + question?.title + '</b></p><span>'
          if (isNotEmpty(question?.value)) {
            var matrixAnswer = question?.value
            for (let i in matrixAnswer) {
              if (question?.getType() === 'matrixdropdown') {
                pdfContent += `${i}-> `
              }
              for (let j in matrixAnswer[i]) {
                pdfContent += ` ${j}: ${matrixAnswer[i][j]}; `
              }
              pdfContent += `<br >`
            }
          }
        } else if (question?.getType() === 'matrix' && question?.include_pdf) {
          pdfContent += '<p><b>' + question?.title + '</b></p><span>'
          if (isNotEmpty(question?.value)) {
            for (const [key, value] of Object.entries(question?.value)) {
              pdfContent += `${key}: ${value} <br>`
            }
          }
        } else if (
          question?.getType() === 'multipletext' &&
          question?.include_pdf
        ) {
          pdfContent +=
            '<p><b>' +
            question?.title +
            '</b></p>' +
            '<span>' +
            Object.values(
              question?.value !== undefined ? question?.value : '',
            ).join(' \n<br>') +
            '</span> <br>'
        } else if (question?.getType() === 'file' && question?.include_pdf) {
          pdfContent += '<p><b>' + question?.title + '</b></p><span>'

          if (isNotEmpty(question?.value)) {
            question?.value?.forEach(function (files) {
              pdfContent +=
                '<li><a href="' +
                env?.applicationHost +
                '/' +
                files?.content +
                '/deploymentMode/' +
                deploymentMode +
                '">' +
                files.name +
                '</a></li>'
            })
            pdfContent += '</span> <br>'
          }
        } else if (
          question?.getType() === 'location' &&
          question?.include_pdf
        ) {
          var locValue = question?.getDisplayValue()

          pdfContent +=
            '<p><b>' +
            question?.title +
            '</b></p>' +
            '<span> Location: ' +
            locValue.location +
            '</span> <br>'
        } else if (
          (question?.getType() === 'workday' ||
            question?.getType() === 'workdaywithoutdefault') &&
          question?.include_pdf
        ) {
          var email = question?.value?.workday
          if (!isNotEmpty(email)) {
            email = 'NIL'
          }
          pdfContent +=
            '<p><b>' +
            question?.title +
            '</b></p>' +
            '<span>' +
            email +
            '</span> <br>'
        } else if (question?.include_pdf) {
          var comment = question?.hasComment
            ? question?.comment !== ''
              ? '<b>Comment:</b> ' + question?.comment
              : ''
            : ''
          pdfContent +=
            '<p><b>' +
            question?.title +
            '</b></p>' +
            '<span> ' +
            question?.getDisplayValue() +
            '</span> <br>' +
            '<span> ' +
            comment +
            '</span> <br>'
        }
      }
      var key = question.getValueName()
      if (result[key] === undefined || result[key] === null) {
        result[key] = ''
      }

      if (question?.create_finding) {
        var actionItem = {
          assessment_question: question?.title,
          requested_remediation: question?.finding_action,
        }
        action_items.push(actionItem)
      }
    })
    if (request?.is_send_email || request?.email_notification_request_list) {
      Object.assign(request, { pdf_content: pdfContent })
    }
    Object.assign(request, { survey_answer_json: result })

    if (action_items?.length > 0) {
      Object.assign(request, { create_finding: true })
    } else {
      Object.assign(request, { create_finding: false })
    }
    Object.assign(request, { action_items: action_items })
    Object.assign(request, {
      findings_owner:
        session?.userInfo?.email + '(' + session?.userInfo?.lanId + ')',
    })

    if (
      surveyAnswerId !== null &&
      surveyAnswerId !== undefined &&
      surveyAnswerId !== ''
    ) {
      updateSurveryResponse(request, surveyAnswerId)
        .then((res) => {
          options.showDataSavingSuccess()
          options.showDataSavingClear()
        })
        .catch((error) => {
          options.showDataSavingError()
        })
    } else {
      createSurveryResponse(request)
        .then((res) => {
          surveyAnswerId = res?.data?.id
          options.showDataSavingSuccess()
          options.showDataSavingClear()
        })
        .catch((error) => {
          options.showDataSavingError()
        })
    }
  }

  Survey.ChoicesRestfull.onBeforeSendRequest = function (sender, options) {
    options.request.setRequestHeader(
      'Authorization',
      session?.userInfo?.accessToken,
    )
    options.request.setRequestHeader('x-api-key', env?.key)
  }

  if (
    Survey.ComponentCollection.Instance.getCustomQuestionByName('date') === null
  ) {
    Survey.ComponentCollection.Instance.add({
      name: 'Date',
      category: 'General',
      questionJSON: {
        type: 'text',
        name: 'question1',
        inputType: 'date',
      },
    })
  }
  if (
    Survey.ComponentCollection.Instance.getCustomQuestionByName(
      'emailnotification',
    ) === null
  ) {
    Survey.ComponentCollection.Instance.add({
      name: 'EmailNotification',
      category: 'Custom',
      elementsJSON: [
        {
          type: 'text',
          name: 'to_email',
          title: 'To',
          hideNumber: true,
        },
        {
          type: 'text',
          name: 'bcc_email',
          title: 'BCC',
          hideNumber: true,
        },
        {
          type: 'text',
          name: 'subject_email',
          title: 'Subject',
          hideNumber: true,
        },
        {
          type: 'quilleditor',
          name: 'body_email',
          title: 'Email body',
          hideNumber: true,
        },
        {
          type: 'boolean',
          name: 'isEmailSend',
          title: 'Send Email?',
          hideNumber: true,
          defaultValue: 'true',
          isRequired: true,
        },
        {
          type: 'boolean',
          name: 'isPdfSend',
          title: 'Send PDF?',
          hideNumber: true,
          defaultValue: 'true',
          isRequired: true,
        },
      ],
    })
  }

  if (
    Survey.ComponentCollection.Instance.getCustomQuestionByName(
      'workdaywithoutdefault',
    ) === null
  ) {
    Survey.ComponentCollection.Instance.add({
      name: 'WorkdayWithOutDefault',
      category: 'Custom',
      elementsJSON: [
        {
          type: 'text',
          name: 'workday',
          title: 'Email',
        },
        {
          type: 'html',
          name: 'requesting1',
          html: 'The data is requesting',
          visibleIf: '{email_request_processing1} = true',
        },
        {
          type: 'html',
          name: 'error1',
          html: '<div style=color:red><b>Please type the email correctly</b></div>',
          visibleIf: '{email_request_error1} = true',
        },
      ],
      onValueChanged: (question, name, value) => {
        if (name === 'workday') {
          getUserData1(question, name, value)
        }
      },
    })
  }

  if (
    Survey.ComponentCollection.Instance.getCustomQuestionByName('workday') ===
    null
  ) {
    Survey.ComponentCollection.Instance.add({
      name: 'Workday',
      category: 'Custom',
      elementsJSON: [
        {
          type: 'text',
          name: 'workday',
          title: 'Email',
          defaultValue: session?.userInfo?.email,
        },
        {
          type: 'html',
          name: 'requesting',
          html: 'The data is requesting',
          visibleIf: '{email_request_processing} = true',
        },
        {
          type: 'html',
          name: 'error',
          html: '<div style=color:red><b>Please type the email correctly</b></div>',
          visibleIf: '{email_request_error} = true',
        },
      ],
      onValueChanged: (question, name, value) => {
        if (
          name === 'workday' &&
          value?.toLowerCase() !== session?.userInfo?.email?.toLowerCase()
        ) {
          getUserData(question, name, value)
        }
      },
    })
  }

  if (
    supervisorEmail !== '' &&
    Survey.ComponentCollection.Instance.getCustomQuestionByName(
      'supervisoremail',
    ) === null
  ) {
    Survey.ComponentCollection.Instance.add({
      name: 'Supervisoremail',
      //The text that shows on toolbox
      title: 'Supervisor Email',
      category: 'Custom',
      questionJSON: {
        type: 'text',
        inputType: 'email',
        name: 'supervisoremail',
        title: 'Supervisor email',
        defaultValue: supervisorEmail,
      },
    })
  }

  if (
    Survey.ComponentCollection.Instance.getCustomQuestionByName('location') ===
    null
  ) {
    Survey.ComponentCollection.Instance.add({
      name: 'Location',
      category: 'Custom',
      elementsJSON: [
        {
          type: 'dropdown',
          name: 'location',
          isRequired: true,
          renderAs: 'select2',
          choicesByUrl: {
            url: env?.fieldAssessment?.host + '/locations?key=' + env?.key,
            valueName: 'location_id',
            titleName: 'location_name',
          },
        },
        { type: 'text', name: 'region', readOnly: true, visible: false },
        { type: 'text', name: 'group', readOnly: true, visible: false },
        { type: 'text', name: 'district', readOnly: true, visible: false },
        { type: 'text', name: 'state', readOnly: true, visible: false },
      ],
      onValueChanged: (question, name, value) => {
        //If combo is changed, then set a double value of "combo" to "text1" and clear "text2"
        if (name === 'location') {
          var filteredLocation = locationsData.filter(
            (l) => l.location_id === value,
          )

          question.setValue('region', filteredLocation[0].region)
          question.setValue('group', filteredLocation[0].group)
          question.setValue('district', filteredLocation[0].district)
          question.setValue('state', filteredLocation[0].state)
        }
      },
    })
  }

  var survey = new Survey.Model(model)
  survey.sendResultOnPageNext = true

  if (surveyAnswer != null) {
    survey.data = surveyAnswer
  }
  if (
    urlLocation?.pathname?.includes('viewAnswers') ||
    ((deploymentStatus === 'Completed' || isSurveyLocked) &&
      !hasAccessAdminGroup)
  ) {
    survey.mode = 'display'
  }

  survey.getAllQuestions().forEach(function (question) {
    if (!hasAccessAdminGroup) {
      if (question.role === 'admin') {
        question.visible = false
      } else if (question.role === 'user-readOnly') {
        question.readOnly = true
      }
    }
  })

  survey.onUploadFiles.add(function (survey, options) {
    const uploadFilesAsync = async () => {
      var count = 0
      var test = []
      for (const file of options.files) {
        try {
          const checkSum = await checkSumFetch(file)
          const formData = new FormData()
          formData.append('file_name', file)
          const result = await uploadFile(formData, checkSum)
          count++
          test.push({
            file: file,
            content: result?.data?.name,
          })
          if (options.files.length === count) {
            options.callback('success', test)
          }
        } catch (error) {
          console.error('Error processing file', error)
          options.callback('error', { file, error })
        }
      }
    }
    uploadFilesAsync()
  })

  const checkSumFetch = async (file) => {
    try {
      let checkSum = await calculateMD5CheckSum(file)
      return checkSum
    } catch (error) {
      console.error('Error calculating md5 checksum', error)
      throw error
    }
  }

  survey.onDownloadFile.add(function (survey, options) {
    downloadFile(options.content, deploymentMode).then((result) => {
      var file = new File([result?.data], options.fileValue.name)
      var reader = new FileReader()
      reader.onload = function (e) {
        options.callback('success', e.target.result)
      }
      reader.readAsDataURL(file)
    })
  })

  survey.onAfterRenderQuestion.add(function (sender, options) {
    if (options.question.getType() === 'file') {
      var container = document.createElement('div')
      container.className = 'my-preview-container'
      var fileElement =
        options.htmlElement.getElementsByClassName('sv_q_file')[0]
      if (!fileElement) {
        fileElement =
          options.htmlElement.getElementsByClassName('sv-file__decorator')[0]
      }
      fileElement.appendChild(container)
      options.question.onPropertyChanged.add(function (question, options) {
        if (options.name === 'value') {
          updatePreview(options.newValue, container)
        }
      })
      updatePreview(options.question.value, container)
    }
  })

  function updatePreview(newValue, container) {
    container.innerHTML = ''
    ;(newValue || []).forEach(function (fileItem) {
      var button = document.createElement('div')
      button.className = 'btn sv-btn sv-file__choose-btn'
      button.innerText = 'Download ' + fileItem.name
      button.onclick = function (e) {
        e.preventDefault()
        downloadFile(fileItem.content, deploymentMode).then((result) => {
          var contentType = 'application/octet-stream'
          var a = document.createElement('a')
          var blob = new File([result?.data], { type: contentType })
          a.href = window.URL.createObjectURL(blob)
          a.download = fileItem.name
          a.click()
        })
      }
      container.appendChild(button)
    })
  }

  function saveSurveyAnswers(sender, deploymentId, deploymentType, showAlert) {
    var result = sender?.data

    var request = {
      deployment_id: deploymentId,
      status: 'Partial',
      deployment_type: deploymentType,
    }
    let isImageQuestion = false
    sender.getAllQuestions().forEach(function (question) {
      if (question?.getType() === 'quilleditor') {
        if (
          question.value !== undefined &&
          question.value !== null &&
          question.value?.includes('<img') &&
          question.value?.includes('src=')
        ) {
          alert(
            question.title +
              ' contains image, kindly remove the image before completing assessment',
          )
          isImageQuestion = true
        }
      }
      var key = question.getValueName()
      if (!result[key]) {
        result[key] = ''
      }
    })
    Object.assign(request, { survey_answer_json: result })
    if (!isImageQuestion) {
      if (
        surveyAnswerId !== null &&
        surveyAnswerId !== undefined &&
        surveyAnswerId !== ''
      ) {
        updateSurveryResponse(request, surveyAnswerId)
          .then((res) => {
            if (showAlert) {
              alert('Assessment response saved successfully.')
            }
            console.log('Assessment response saved successfully.')
          })
          .catch((error) => {})
      } else {
        createSurveryResponse(request)
          .then((res) => {
            surveyAnswerId = res?.data?.id
            if (showAlert) {
              alert('Assessment response saved successfully.')
            }
            console.log('Assessment response saved successfully.')
          })
          .catch((error) => {})
      }
    }
  }

  // function addSaveButton() {
  //   if (!isLoading && allLocations.length && survey?.include_save_button) {
  //     var svNavDiv = $('.sv_nav').length
  //     var svNavButton = $('.sv_nav').children('button').length

  //     if (svNavDiv !== 0 && svNavButton === 0) {
  //       if (svNavDiv > 0) {
  //         var surveySaveBtn1 = document.createElement('button')
  //         surveySaveBtn1.onclick = function () {
  //           saveSurveyAnswers(survey, deploymentId, deploymentType, true)
  //         }

  //         surveySaveBtn1.innerHTML = 'Save'
  //         surveySaveBtn1.style.cssText =
  //           'position: absolute;right: 215px; margin-left: 20px'
  //         $('.sv_nav')[0].prepend(surveySaveBtn1)
  //       }
  //       if (svNavDiv > 1) {
  //         var surveySaveBtn2 = document.createElement('button')
  //         surveySaveBtn2.onclick = function () {
  //           saveSurveyAnswers(survey, deploymentId, deploymentType, true)
  //         }

  //         surveySaveBtn2.innerHTML = 'Save'
  //         surveySaveBtn2.style.cssText =
  //           'position: absolute;right: 215px; margin-left: 20px'
  //         $('.sv_nav')[1].prepend(surveySaveBtn2)
  //       }
  //     }
  //   }
  // }
  if (
    !urlLocation?.pathname?.includes('viewAnswers') &&
    survey?.include_save_button
  ) {
    survey.addNavigationItem({
      id: 'survey_save_current_page',
      title: 'Save',
      visibleIndex: 49, // "Complete" button has the visibleIndex 50.
      action: () => {
        console.log('save button clicked')
        saveSurveyAnswers(survey, deploymentId, deploymentType, true)
      },
    })
  }

  // if (!isLoading && allLocations.length) {
  //   setTimeout(addSaveButton, 200)
  // }

  return (
    <React.Fragment>
      <div className="container">
        {!isLoading && allLocations.length > 0 ? (
          <>
            <Grid container justify="space-between">
              {surveyAnswerId !== null ? (
                <Grid item xs={11} align="right">
                  <Typography inline align="right">
                    <div
                      style={{
                        color: '#a62e37',
                        font: 'Helvetica',
                        fontSize: '26px',
                      }}
                    >
                      Record ID: {surveyAnswerId}
                    </div>
                  </Typography>
                </Grid>
              ) : (
                <Grid item xs={11} align="right">
                  <Typography inline align="right">
                    <div
                      style={{
                        color: '#a62e37',
                        font: 'Helvetica',
                        fontSize: '26px',
                      }}
                    >
                      Record ID: UA
                    </div>
                  </Typography>
                </Grid>
              )}
              <Grid item xs={12}>
                <Survey.Survey
                  model={survey}
                  onComplete={onComplete}
                  onCompleting={onCompleting}
                  onPartialSend={onPartialSend}
                  onDynamicPanelAdded={onDynamicPanelAdded}
                  align="left"
                />
              </Grid>
            </Grid>
          </>
        ) : (
          <CircularLoading />
        )}
      </div>
    </React.Fragment>
  )

  function updateEmailContent(question, result, request, keyName, sender) {
    if (question.value !== undefined && question.value !== null) {
      var emailResult = question?.value
      var bodyMatches = question?.value?.match(/[^{]+(?=})/g)

      emailResult = UpdateEmailBodyMatchedQuestionValues(
        bodyMatches,
        result,
        sender,
        emailResult,
      )
      Object.assign(request, { [keyName]: emailResult })
    }
  }

  function UpdateEmailBodyMatchedQuestionValues(
    bodyMatches,
    result,
    sender,
    emailResult,
  ) {
    for (var i = 0; i < bodyMatches?.length; i++) {
      var element = bodyMatches[i]
      const myquestionSplitArray = bodyMatches[i].split('.')
      if (myquestionSplitArray.length === 2) {
        element = myquestionSplitArray[0]
      }
      var resultValue = result[element]

      var answerBody = ''
      var filterQuestion = sender?.getQuestionByName(element)
      if (
        filterQuestion !== null &&
        filterQuestion !== undefined &&
        filterQuestion?.getType() === 'file'
      ) {
        emailResult = emailResult?.replace(
          '{' + element + '}',
          filterQuestion?.value !== undefined &&
            filterQuestion?.value !== null &&
            filterQuestion?.value.length > 0
            ? filterQuestion?.value
                .map((f) => {
                  return (
                    env?.toss?.host +
                    '/' +
                    env?.toss?.bucketName +
                    '/' +
                    f.content
                  )
                })
                .join(' \n')
            : '',
        )
      } else if (
        filterQuestion !== null &&
        filterQuestion?.value !== undefined &&
        filterQuestion?.getType() === 'matrix'
      ) {
        if (isNotEmpty(filterQuestion?.value)) {
          for (const [key, value] of Object.entries(filterQuestion?.value)) {
            answerBody += `${key}: ${value} <br>`
          }
        }

        emailResult = emailResult?.replace('{' + element + '}', answerBody)
      } else if (
        filterQuestion !== null &&
        filterQuestion?.value !== undefined &&
        (filterQuestion?.getType() === 'matrixdropdown' ||
          filterQuestion?.getType() === 'matrixdynamic')
      ) {
        if (isNotEmpty(filterQuestion?.value)) {
          var matrixAnswer = filterQuestion?.value
          for (let i in matrixAnswer) {
            if (filterQuestion?.getType() === 'matrixdropdown') {
              answerBody += `${i}-> `
              answerBody += ` `
            }
            for (let j in matrixAnswer[i]) {
              answerBody += ` ${j}: ${matrixAnswer[i][j]}; `
            }
            answerBody += `<br >`
          }
        }

        emailResult = emailResult?.replace('{' + element + '}', answerBody)
      } else if (
        filterQuestion !== null &&
        filterQuestion?.value !== undefined &&
        filterQuestion?.getType() === 'multipletext'
      ) {
        emailResult = emailResult?.replace(
          '{' + element + '}',
          Object.values(
            filterQuestion?.value !== undefined ? filterQuestion?.value : '',
          ).join(' \n'),
        )
      } else if (resultValue?.workday) {
        emailResult = emailResult?.replace(
          '{' + element + '}',
          resultValue?.workday,
        )
      } else if (
        filterQuestion !== null &&
        filterQuestion?.value !== undefined &&
        filterQuestion?.getType() === 'location'
      ) {
        var locValue = filterQuestion?.getDisplayValue()
        if (myquestionSplitArray.length === 2) {
          if (myquestionSplitArray[1] === 'location') {
            const myArraylocName = locValue?.location?.split('(Region')
            emailResult = emailResult?.replace(
              '{' + bodyMatches[i] + '}',
              myArraylocName[0],
            )
          } else if (myquestionSplitArray[1] === 'region') {
            emailResult = emailResult?.replace(
              '{' + bodyMatches[i] + '}',
              locValue?.region,
            )
          } else if (myquestionSplitArray[1] === 'group') {
            emailResult = emailResult?.replace(
              '{' + bodyMatches[i] + '}',
              locValue?.group,
            )
          } else if (myquestionSplitArray[1] === 'district') {
            emailResult = emailResult?.replace(
              '{' + bodyMatches[i] + '}',
              locValue?.district,
            )
          } else if (myquestionSplitArray[1] === 'state') {
            emailResult = emailResult?.replace(
              '{' + bodyMatches[i] + '}',
              locValue?.state,
            )
          }
        } else {
          emailResult = emailResult?.replace(
            '{' + element + '}',
            locValue?.location,
          )
        }
      } else if (filterQuestion !== null) {
        emailResult = emailResult?.replace(
          '{' + element + '}',
          filterQuestion.getDisplayValue(),
        )
      }
    }
    return emailResult
  }

  function getUserData(question, name, value) {
    question.setVariable('email_request_processing', false)
    question.setVariable('email_request_error', false)
    if (value.trim() !== '') {
      question.setVariable('email_request_processing', true)
      question.setVariable('email_request_error', false)
      getRecipientsByEmailId(value)
        .then((res) => {
          question.setVariable('email_request_processing', false)
        })
        .catch((error) => {
          question.setVariable('email_request_processing', false)
          question.setVariable('email_request_error', true)
        })
    }
  }

  function getUserData1(question, name, value) {
    question.setVariable('email_request_processing1', false)
    question.setVariable('email_request_error1', false)
    if (value.trim() !== '') {
      question.setVariable('email_request_processing1', true)
      question.setVariable('email_request_error1', false)
      getRecipientsByEmailId(value)
        .then((res) => {
          question.setVariable('email_request_processing1', false)
        })
        .catch((error) => {
          question.setVariable('email_request_processing1', false)
          question.setVariable('email_request_error1', true)
        })
    }
  }
}
